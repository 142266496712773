.product-name {
  border: 1px solid #eeeeee;
  border-radius: 8px;
  padding: 21px 23px 26px;
  display: flex;
  flex-direction: column;
  min-height: 260px;
  &--skeleton {
    min-height: unset;
    &--twice {
      display: flex;
      span:nth-child(2) {
        margin-left: auto;
      }
    }
    &--mid span {
      margin-top: 33px;
      margin-bottom: 69px;
    }
    &--bottom span {
      margin-top: 20px;
      margin-bottom: 100px;
    }
  }
  @media (min-width: 756px) {
    width: 334px;
  }
  &--label {
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    text-transform: uppercase;
  }
  &--price {
    color: #262626;
    font-weight: 600;
    font-size: 36px;
    margin-top: 13px;
    line-height: 52px;
    @media (min-width: 756px) {
      margin-top: 0;
    }
    .currency {
      margin-left: 8px;
      text-transform: uppercase;
    }
  }
  &--price-brutto {
    color: #212121;
    font-size: 12px;
    margin-bottom: 39px;
  }
  &--bottom {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      border-top: 1px solid #eaeaea;
      left: -23px;
      bottom: -15px;
      z-index: -1;
      width: 334px;
      height: 1px;
      @media (max-width: 756px) {
        width: calc(100% + 44px);
        left: -20px;
        bottom: -17px;
      }
    }
  }
  &--date {
    color: #bbbbbb;
    font-size: 12px;
    &--margin-top-20px {
      margin-top: 20px;
    }
  }
  &--watching {
    position: relative;
    &:hover &--tooltip {
      visibility: visible;
      transition: 0.3s;
    }
    &--tooltip {
      position: absolute;
      background-color: #eeeeee;
      z-index: 999;
      width: 159px;
      padding: 16px;
      color: #000000;
      font-size: 10px;
      visibility: hidden;
      text-transform: none;
      bottom: -77px;
      @media (max-width: 756px) {
        left: -100px;
      }
    }
  }
  &--count {
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
    color: #919191;
    &--margin-top {
      margin-top: 30px;
    }
  }
  .input-counter {
    height: 30px;
    .decrease {
      border-right: 0;
      margin-right: -2px;
      padding: 0 12px;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      border: 1px solid #cbcbcb;
    }
    .increase {
      border-left: 0;
      margin-left: -2px;
      padding: 12px;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      border: 1px solid #cbcbcb;
      border-left: none;
      margin-right: 12px;
    }
    .button-disabled {
      color: #d6d6d6;
      pointer-events: none;
    }
    .input {
      max-width: 86px;
      border: 1px solid #cbcbcb;
    }
    .in_stock {
      font-weight: 400;
      font-size: 10px;
      line-height: 15px;
      color: #919191;
    }
  }
}
