.login {
  &__wrapper {
    min-height: calc(100vh - 45px);
    align-content: flex-start;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    @media (max-width: 1024px) {
      margin-bottom: 100px;
    }
  }
  &__logo {
    margin: 50px 0;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  &__form {
    width: 100%;
    max-width: 684px;
    background-color: #f3f3f3;
    padding: 63px 17px;
    display: flex;
    justify-content: center;
    border-radius: 16px;
    @media (min-width: 768px) {
      margin-bottom: 75px;
    }
    .capitalize {
      text-transform: capitalize;
    }
  }
  &__item {
    width: 330px;
    .regular-field__label {
      text-transform: uppercase;
      color: #272727;
    }
    &--send {
      width: 400px;
    }
    .btn {
      width: 100%;
      margin-top: 40px;
    }
    a {
      text-decoration: none;
    }
  }
  &--header {
    text-align: center;
    font-size: 20px;
    line-height: 30px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 20px;
    &--small {
      text-transform: unset;
    }
  }
  &__paragraph {
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    color: #bbbbbb;
    &--black {
      color: #000;
      text-align: center;
      margin-bottom: 30px;
    }
    &--bottom {
      margin-top: 31px;
      text-align: center;
    }
    a,
    span {
      color: #ffa914;
      font-weight: 600;
      text-decoration: none;
      cursor: pointer;
    }
  }
}

.eye {
  &__wrapper {
    position: relative;
  }
}
