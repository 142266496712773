.courier {
  &__list {
    margin-top: 39px;
  }
  &__item {
    border: 1px solid #dddddd;
    border-radius: 16px;
    padding: 22px 20px;
    display: flex;
    font-size: 14px;
    display: grid;
    grid-template-columns: 2fr 2fr 2fr 2fr 1fr;
    align-items: center;
    position: relative;
    @media (max-width: 756px) {
      font-size: 12px;
      grid-template-columns: 3fr 3fr 2fr 1fr;
    }
    &:nth-child(n + 2) {
      margin-top: 10px;
    }
    &:last-child {
      margin-bottom: 50px;
    }
    &--settings {
      display: flex;
      justify-content: center;
      svg {
        margin-left: 20px;
        width: 20px;
        height: 20px;
        cursor: pointer;
      }
    }
    &--logo {
      width: 84px;
      height: 34px;
      img {
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }
    &--price span {
      text-transform: uppercase;
    }
    &--settings {
      cursor: pointer;
      position: relative;
      &-disabled {
        pointer-events: none;
      }
    }
  }
  &__add {
    &--paragraph {
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      margin-top: 10px;
    }
    &--switch {
      margin-top: 10px;
    }
    .regular-field {
      &__label {
        color: #272727;
      }
    }
    .regular-field__label {
      text-transform: uppercase;
    }
    &--currency {
      display: flex;
      align-items: center;
      width: 50%;
    }
    &--country {
      .select-field__error-label {
        margin-left: 0;
        font-size: 12px;
      }
    }
    &--price-wrapper {
      display: flex;
      margin-top: 22px;
      justify-content: space-between;
      .regular-field {
        margin-right: 15px;
        align-items: center;
      }
      .react-select__control {
        height: 41px;
      }
      .react-select__indicator {
        margin-left: -5px;
      }
      .react-select__indicators {
        margin-left: -10px;
      }
      .react-select__value-container {
        width: 30px !important;
        max-width: 100px !important;
      }
      .select-field__error-label {
        margin-left: 0;
        font-size: 12px;
      }
      .select-field {
        margin-top: 16px !important;
        &__wrapper {
          width: calc(100% - 193px);
          @media (max-width: 360px) {
            width: calc(100% - 40px);
          }
        }
      }
    }
    &--buttons {
      max-width: 100%;
      justify-content: space-around;
      .btn {
        padding: 10px 42px;
        max-width: 200px;
      }
      @media (max-width: 756px) {
        justify-content: center;
        margin-top: -20px;
        .btn {
          max-width: 100%;
        }
      }
    }
    &__photo--wrapper {
      margin-top: 30px;
      .add__photo--catManag--name {
        background-color: #dad8d8;
        p {
          color: black;
        }
      }
      .add__photo--catManag .btn--gray {
        background-color: #dad8d8;
      }
      .paragraph {
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
      }
    }
  }

  &__settings {
    position: absolute;
    right: 50px;
    top: 50px;
    border: 1px solid #eeeeee;
    border-radius: 4px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
    padding: 13px 22px;
    background-color: #fff;
    z-index: 10;
    @media (max-width: 756px) {
      padding: 13px 52px 13px 22px;
      right: 20px;
    }
    &--disabled {
      color: #929292 !important;
      cursor: default !important;
    }
    &--tooltip {
      position: absolute;
      background-color: #eeeeee;
      z-index: 999;
      width: 159px;
      padding: 16px;
      color: #000000;
      font-size: 10px;
      visibility: hidden;
      text-transform: none;
      @media (max-width: 756px) {
        left: -100px;
      }
    }
    div {
      text-transform: capitalize;
      font-size: 14px;
      line-height: 150%;
      cursor: pointer;
      &:nth-child(2) {
        margin-top: 11px;
        color: #fc4e4e;
        &:hover .courier__settings--tooltip {
          visibility: visible;
          transition: 0.3s;
        }
      }
    }
  }
}
