.empty-basket {
  &__wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  &__content {
    width: 270px;
    .head {
      font-size: 16px;
      line-height: 147%;
      font-weight: 500;
      display: flex;
      justify-content: center;
    }
  }
  &__actions {
    margin-top: 80px;
    .btn {
      width: 100%;
      &--orange {
        margin-top: 15px;
      }
    }
  }
}
