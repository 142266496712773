.coordinator {
  .regular-field__label {
    color: #272727;
  }
}
.categories {
  .modal-actions__content {
    .btn:first-child {
      margin-right: 20px !important;
    }
  }
}
.categoryManagement {
  &__assign-client {
    width: 100%;
    max-width: 301px;
  }
  &__wrapper {
    width: 100%;
    min-height: calc(100vh - 50px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    .btn p {
      color: #000 !important;
    }
    @media (max-width: 756px) {
      margin-bottom: 50px;
    }
  }
  &__form {
    &--constant {
      &--heading {
        color: #929292;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 8px;
      }
      margin-bottom: 20px;
      .top {
        color: #b0b0b0;
        font-size: 12px;
        font-weight: 500;
        text-transform: uppercase;
        line-height: 150%;
      }
      .bottom {
        font-size: 18px;
        font-weight: 400;
        line-height: 150%;
      }
    }
  }
  &__content {
    width: 100%;
    height: 100%;
    padding-top: 69px;
  }
  &--heading {
    font-size: 16px;
    font-weight: 500;
  }
  &__header {
    margin-top: 62px;
    display: flex;
    border-bottom: 1px solid #eeeeee;
    padding-bottom: 15px;
    @media (max-width: 756px) {
      margin-top: 30px;
      border-bottom: unset;
    }
    &--button {
      padding: 9px 25px;
      color: black;
      @media (max-width: 756px) {
        width: 100%;
      }
    }
    #add-category--button{
      @media (max-width: 756px) {
        max-width: 100%;
      }
    }
    &__left {
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 274px;
      width: 100%;
      &--paragraph {
        @media (max-width: 756px) {
          display: none;
        }
      }
      p {
        font-size: 14px;
        font-weight: 400;
      }
    }
    &__right {
      display: flex;
      align-items: center;
      margin-left: 55px;
      @media (max-width: 756px) {
        display: none;
      }
      p {
        font-size: 14px;
      }
    }
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
  }
  &__singleCategory {
    display: flex;
    margin-top: 39px;
    padding-bottom: 50px;
    border-bottom: 1px solid #eeeeee;
    width: 100%;
    @media (max-width: 756px) {
      flex-wrap: wrap;
      border-bottom: unset;
      margin-top: 20px;
      padding-bottom: 0;
    }
    &--active {
      border: 1px solid #eeeeee;
      border-radius: 16px;
    }
    &--expand {
      cursor: pointer;
      margin-bottom: 13px;
      margin-left: 29px;
      &--hide {
        display: none;
      }

      &--true {
        transform: rotate(180deg);
        transition: 0.3s;
      }
      &--icon {
        margin-bottom: -3px;
        transition: 0.3s;
      }
    }
    &--dots {
      cursor: pointer;
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      &--none {
        pointer-events: none;
      }
    }
    &--addSubCat {
      margin-left: auto;
      margin-right: 32px;
      margin-bottom: 12px;
      @media (max-width: 756px) {
        margin-right: 0;
      }
    }
    &--category {
      background-color: #eeeeee;
      padding: 15px 24px 15px 31px;
      border-radius: 16px;
      display: flex;
      justify-content: space-between;
      transition: 0.3s;
      align-items: center;
      @media (max-width: 756px) {
        padding: 5px 20px;
      }
      &--active {
        background-color: #fff;
        transition: 0.3s;
      }
      &--label {
        text-transform: capitalize;
        @media (max-width: 756px) {
          height: 40px;
          display: flex;
          align-items: center;
          width: 100%;
          cursor: pointer;
        }
      }
      &--icon {
        margin-right: 15px;
        transition: 0.3s;
        &--rotate {
          transform: rotate(180deg);
          transition: 0.3s;
        }
        path {
          fill: #000;
        }
      }
    }
    &__left {
      max-width: 274px;
      width: 100%;
      position: relative;
      @media (max-width: 756px) {
        max-width: 100%;
      }
    }
    &__right {
      display: flex;
      align-items: center;
      margin-left: 55px;
      flex-wrap: wrap;
      max-height: 136px;
      transition: 0.3s;
      @media (max-width: 756px) {
        max-height: 0px;
        margin-left: 0;
        visibility: hidden;
        overflow: hidden;
      }
      &--mobileExpand {
        display: flex;
        padding: 0 12px 20px 12px;
        max-height: 2500px;
        transition: 0.3s;
        margin-top: 20px;

        visibility: visible;
      }
      &--expand {
        max-height: 1200px;
        transition: 0.3s;
        overflow: unset;
        @media (max-width: 756px) {
          display: none;
        }
      }
    }
  }
  &__singleSubcat {
    padding: 5px 0 5px 31px;
    display: flex;
    justify-content: space-between;
    border: 1px solid #eeeeee;
    border-radius: 16px;
    margin-right: 11px;
    position: relative;
    transition: visibility 0s linear 0s, opacity 300ms;
    margin-bottom: 13px;

    &--label {
      display: flex;
      align-items: center;
      text-transform: capitalize;
    }
    &--none {
      visibility: hidden;
      width: 0;
      height: 0;
      padding: 0;
      margin: 0;
    }
    &--dots {
      cursor: pointer;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 32px;
      margin-right: 10px;
      &--none {
        pointer-events: none;
      }
    }
  }
  &__addCategory {
    &__wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      .courier__add{
        @media (max-width: 756px) {
          width: 100%;
        }
        width: 80%;
      }
    }
    &__content {
      max-width: 301px;
      .regular-field__label {
        color: black;
        text-transform: uppercase;
      }
      input,
      .react-select__control {
        border: 0.5px solid #bdbdbd;
      }
      .react-select__control {
        height: 42px !important;
      }
    }
  }
  &__subcategory {
    .regular-field__label {
      border: 0;
    }
  }
  &__settings {
    position: absolute;
    right: -69px;
    top: 30px;
    background-color: #fff;
    padding: 12px 20px;
    border: 1px solid #eeeeee;
    border-radius: 4px;
    min-width: 107px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
    z-index: 1;
    @media (max-width: 756px) {
      right: 20px;
    }
    &--hidden {
      max-height: 0px;
      visibility: hidden;
      overflow: hidden;
      transition: 0.1s;
    }
    &--visible {
      max-height: 200px;
      visibility: visible;
      transition: 0.1s;
    }
    &--item {
      letter-spacing: 0.022em;
      font-size: 14px;
      line-height: 150%;
      cursor: pointer;
      &--disabled {
        opacity: 0.1;
        pointer-events: none;
      }
      &:first-child {
        margin-bottom: 9px;
      }
    }
  }
}
