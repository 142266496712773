.select-field {
  width: 100%;
  margin: 16px 0px;
  font-family: 'Poppins' !important;
  &__wrapper {
    position: relative;
    width: 100%;
  }
  &__label {
    position: absolute;
    top: 11px;
    left: 6px;
    font-size: 10px;
    font-weight: 500;
    color: #ffa914;
    text-transform: uppercase;
    &--is-disabled {
      color: #bdbdbd !important;
    }
    // background: linear-gradient(transparent 0%, white 50%);
    background: linear-gradient(transparent 40%, white 40%);
    @media (max-width: 756px) {
      background: transparent;
      &:after {
        content: '';
        width: 100%;
        height: 1px;
        background-color: white;
        display: block;
        margin-top: -6px;
      }
    }
    padding: 0 2px;
    z-index: 1;
  }
  &__input {
    &--error {
      .react-select__control {
        border: 1px solid #e20d0d !important;
      }
    }
  }
  &__error-label {
    font-size: 11px;
    font-weight: $weight__regular;
    color: #ff3333;
    margin: 5px 0 0 10px;
    &-only2 {
      margin-top: 15px;
      margin-left: 2px;
    }
  }
  .react-select {
    
    &__control {
      background-color: #fff;
      border: none;
      border-color: black;

      &--is-focused {
        border: unset;
        box-shadow: 0 0 0 1px rgba(255, 169, 20, 0.5);
        // border: 1px solid black;
      }
      &--menu-is-open {
        .svg--chevron {
          transform: rotate(180deg);
        }
      }
    }
    &__indicator-separator {
      display: none;
    }

    &__menu {
      box-shadow: 2px 4px 11px #00000014;
      z-index: 99;
    }
    &__value-container {
      padding-left: 17px;
    }
    &__placeholder {
      color: #272727;
      font-size: 14px;
      font-weight: 400;
    }
    &__menu,
    &__single-value,
    &__input {
      font-size: 12px;
      font-weight: $weight__regular;
      font-family: Poppins;
    }
    &__single-value {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #272727;
      &--is-disabled {
        color: #bdbdbd;
      }
    }
    &--is-disabled {
      path {
        fill: #bdbdbd;
      }
    }
  }
}
.css-lpahdxg-control {
  border-color: black !important;
}
.select--disabled {
  pointer-events: none;
  .react-select__control {
    cursor: not-allowed;
  }
  .select-field__label,
  .react-select__placeholder,
  .svg {
    cursor: not-allowed;
    path {
      fill: #c4c4c4;
    }
  }
}
.react-select__option--is-focused{
  background-color: #EEEEEE !important;
}