.chat {
  position: fixed;
  max-width: 1038px;
  width: calc(100% - 42px);
  bottom: 110px;
  z-index: 999;
  &--footer {
    position: relative;
    margin-left: 42px;
  }
  &__content {
    width: 100%;
    position: relative;
  }

  &__icon {
    position: absolute;
    right: -80px;
    cursor: pointer;
    .icon {
      position: relative;
    }
    .dot {
      position: absolute;
      top: 0;
      right: 0;
      width: 15px;
      height: 15px;
      background-color: #ffa914;
      border-radius: 10px;
    }
    svg {
      position: relative;
    }
    @media (max-width: 1200px) {
      right: -40px;
    }
    @media (max-width: 1130px) {
      right: 0px;
    }
  }
  &--box {
    border-radius: 16px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
    background: #ffffff;
    width: 339px;
    height: 411px;
    position: absolute;
    right: -80px;
    bottom: 20px;
    display: flex;
    flex-direction: column;
    @media (max-width: 1200px) {
      right: -40px;
    }
    @media (max-width: 1130px) {
      right: 0px;
    }
    &--dash {
      cursor: pointer;
      width: 20px;
      height: 20px;
      margin-bottom: 14px;
    }
    &__head {
      padding: 19px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #eeeeee;
      .icon {
        margin-right: 12px;
      }
      div {
        display: flex;
        align-items: center;
        font-size: 12px;
        font-weight: 600;
      }
    }
    &__content {
      padding: 0 19px 0 19px;
      margin-bottom: 20px;
      scrollbar-width: none;
      overflow-y: auto;
      overflow-x: visible;
      &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
      }
      .time {
        color: #929292;
        font-size: 10px;
        line-height: 150%;
        margin-top: 4px;
        &--right {
          text-align: right;
        }
      }
      .text {
        padding: 14px 16px;
        font-size: 12px;
        line-height: 150%;
        margin-top: 19px;

        &__container {
          &--client {
            margin-left: 30px;
            .time {
              text-align: right;
            }
          }
          &--assistance {
            margin-right: 30px;
          }
        }
        &--assistance {
          border-radius: 16px;
          border-top-left-radius: 4px;
          background-color: #eeeeee;
        }
        &--client,
        &--partner {
          color: #ffffff;
          border-radius: 16px;
          border-bottom-right-radius: 4px;
          background-color: #ffa914;
        }
        &--admin {
          color: #ffffff;
          border-radius: 16px;
          border-bottom-right-radius: 4px;
          background-color: #272727;
        }
        &--coordinator {
          color: black;
          border-radius: 16px;
          border-bottom-right-radius: 4px;
          background-color: #eeeeee;
        }
      }
    }
  }
  &__form {
    display: flex;
    width: 100%;
    padding: 0 19px;
    &--wrapper {
      display: flex;
      justify-content: center;
      margin-top: auto;
      margin-bottom: 20px;
    }
    &--btn {
      background-color: #ffa914;
      border-radius: 4px;
      padding: 10px;
      cursor: pointer;
      border: unset;
      margin-left: 10px;
    }
    .regular-field {
      width: 100%;
      position: relative;
      input {
        font-family: 'Poppins';
        font-size: 12px;
        font-weight: 400;
      }
    }
    .regular-field__label-error {
      position: absolute;
      bottom: -23px;
    }
    #validate--content {
      border: 1px solid #DDDDDD;
      border-radius: 4px;
      width: 100%;
      padding: 0 10px;
      color: black;
      font-size: 12px;
      line-height: 150%;
      margin: 0;
      height: 48px;
      &:focus {
        outline: none !important;
      }
    }
  }
}
