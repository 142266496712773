.product-descript {
  margin: 15px 0 30px;
  // border-bottom: 1px solid #eaeaea;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    border-top: 1px solid #eaeaea;
    left: -57px;
    bottom: -30px;
    z-index: -1;
    width: calc(100% + 114px);
    height: 1px;
    @media (max-width: 756px) {
      width: 100%;
      left: 0;
    }
  }
  &--heading {
    color: #19191d;
    font-weight: 600;
    font-size: 16px;
    // padding-bottom: 21px;
    padding-left: 20px;
  }
  &--nrKat {
    margin: 22px 0;
    padding-left: 20px;
    font-weight: 400;
    font-size: 12px;
    color: #272727;
  }
  &--lists {
    display: flex;
    justify-content: flex-start;
    gap: 70px;
    padding: 0 20px;
  }
  &__list {
    display: block;
    font-size: 12px;
    line-height: 18px;
    &--left {
      font-weight: 400;
      li::first-letter {
        text-transform: uppercase;
      }
    }
    &--right {
      font-weight: 600;
    }
  }
}
