.menu {
  &__logo {
    height: 43px;
    width: 63px;
    flex-shrink: 0;
    position: relative;
    display: flex;
    align-items: center;

    &--click {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
    &--item {
      width: 100%;
      height: 100%;
    }
  }
  &__icon {
    margin-right: 10px;
    path {
      fill: #272727;
    }
  }
  &__empty-login {
    width: 100px;
  }

  &__wrapper {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #eaeaea;
    max-width: 1037px;
    width: calc(100% - 60px);
    background-color: #fff;
    z-index: 999;
    margin-top: -45px;
    @media (max-width: 756px) {
      justify-content: flex-start;
    }
    a {
      text-decoration: none;
    }
    &:before {
      content: '';
      width: 9999px;
      position: absolute;
      height: 100%;
      top: 0;
      right: 100%;
      border-bottom: 1px solid #eaeaea;
      background-color: #fff;
    }
    &:after {
      content: '';
      width: 9999px;
      position: absolute;
      height: 100%;
      top: 0;
      left: 100%;
      border-bottom: 1px solid #eaeaea;
      background-color: #fff;
    }
    @media (max-width: 756px) {
      padding: 5px 0;
    }
    .basket {
      margin-right: 20px;
    }
  }
  &__items {
    display: flex;
    align-items: center;
    margin-left: auto;
  }
  &__item {
    text-decoration: none;
    cursor: pointer;
    color: #272727;
    padding: 16px 25px;
    font-size: 12px;
    display: flex;
    align-items: center;
    font-weight: 400;
    position: relative;
    @media (max-width: 1024px) {
      padding: 16px;
      text-align: center;
    }
    &--active {
      font-weight: 600;
      path {
        stroke: black;
        stroke-width: 0.5;
      }
    }
    &--wrapper {
      position: relative;
      display: flex;
      .btn {
        margin: -6px 0;
      }
    }
    &--disabled {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }
  }
  &__phone {
    color: #000;
    display: flex;
    align-items: center;
    font-size: 12px;
    flex-shrink: 0;
    margin-right: 5px;
    &--img {
      margin-left: 8px;
    }
  }
}

#subMenu--3 {
  width: 165px;
}
.subMenu {
  display: none;
  position: relative;

  &__half {
    flex-shrink: 0;
    max-width: 227px;
  }
  &__category {
    width: 0px;
    overflow: hidden;
    .subMenu__subcategorylist--item {
      text-transform: capitalize;
      padding: 0;
      font-size: 0;
    }
    &--active {
      width: 380px;
      padding-left: 32px;
      transition: 0.1s;
      .subMenu__subcategorylist--item {
        padding: 7px 15px;
        font-size: 12px;
      }
    }
  }

  &__all-category {
    font-size: 12px;
    line-height: 18px;
    position: relative;
    text-transform: capitalize;
    cursor: pointer;
    a {
      color: #929292;
    }
  }
  &__heading {
    display: flex;
    justify-content: space-between;
  }
  &--header {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    padding-bottom: 7px;
    padding-left: 15px;
  }
  &--active {
    display: flex;
    background-color: #fff;
    position: absolute;
    top: 40px;
    left: 24px;
    border: 0.5px solid #dddddd;
    border-radius: 4px;
    padding: 15px 21px 15px 16px;
    // width: 165px;
    z-index: 9;
  }
  &__main {
    min-width: 250px;
  }
  &__help {
    width: 221px;
    flex-wrap: wrap;
    height: auto;
    &--contact {
      font-size: 12px;
    }
    &--icon {
      display: flex;
      align-items: center;
      margin: 20px 0;
      svg {
        margin-right: 10px;
      }
      a {
        text-decoration: underline;
        color: #000;
      }
    }
  }
  &__myAccount {
    // max-height: 230px;
    .subMenu__list--item:last-child {
      color: #fc4e4e;
    }
  }

  &__fullWidth {
    width: 100%;
    margin-top: -8px;
    .subMenu--header {
      display: none;
    }
  }

  &__list,
  &__subcategorylist {
    display: flex;
    flex-wrap: wrap;
    &--item {
      padding: 7px 15px;
      font-size: 12px;
      line-height: 18px;
      font-weight: 400;
      width: 100%;
      color: #000;
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      transition: 0.2s;
      &--active {
        background-color: #f5f5f5;
      }
    }
    &--item-icon {
      transform: rotate(270deg);
    }
  }
  &__more {
    padding: 7px 15px;
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    width: 100%;
    color: #000;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    font-weight: 600;
    &--hidden {
      display: none;
    }
  }
  &__list {
    &--item {
      text-transform: capitalize;
      &--hidden {
        height: 0;
        overflow: hidden;
        padding: 0 15px;
        transition: 0.2s;
      }
    }
  }
}

.menu-mobile {
  &--login {
    width: 100%;
    margin-right: 20px;
    width: 140px;
  }
  &__bottom {
    margin-top: auto;
    margin-bottom: 120px;
  }
  &--logOut {
    color: #fc4e4e;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1px;
    margin-right: 40px;
  }
  &__subcategories {
    &__wrapper {
      display: flex;
      flex-direction: column;
      height: 0;
      margin-left: 800px;
      transition: 0.4s;
      width: 100%;
      &--active {
        transition: 0.4s;
        height: 300px;
        margin-left: 0;
      }
    }
    &--item {
      font-size: 18px;
      color: #f5f5f5;
      font-weight: 600;
      padding: 20px 0;
    }
    &--show-all {
      display: flex;
      justify-content: center;
      border: 1px solid #ffa914;
      padding: 11px 0;
      border-radius: 50px;
      &--paragraph {
        font-size: 12px;
        color: #ffa914;
        font-weight: 500;
      }
    }
  }
  &--contact {
    color: #fff;
    a {
      color: #fff;
      text-decoration: none;
    }
    div {
      margin: 30px 0;
    }
  }
  &__wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    margin-left: -30px;
    background-color: rgba($color: #000000, $alpha: 0.3);
    z-index: 9999;
  }
  &__icon {
    &--wrapper {
      align-items: center;
      display: flex;
      margin-left: auto;
      path {
        fill: #000000;
      }
    }
    &--other,
    &--menu {
      cursor: pointer;
      flex-shrink: 0;
    }
    &--other {
      margin: 0 8px;
    }
    &--menu {
      margin-left: 18px;
    }
  }
  &--header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &--close {
    cursor: pointer;
    margin-right: 10px;
  }
  &__content {
    position: absolute;
    right: -1000px;
    top: 0;
    height: 100%;
    width: calc(100vw - 90px);
    background-color: #272727;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    padding: 20px 20px 20px 54px;
    transition: 0.4s;
    overflow-y: auto;
    overflow-x: hidden;
    &--active {
      right: -2px;
      transition: 0.4s;
    }
  }
  &__list {
    max-height: calc(100% - 100px);
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 45px;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    overflow-x: hidden;
    // overscroll-behavior: contain;
    .menu-mobile__list--wrapper {
      &:nth-child(2) {
        order: -4;
      }
      &:nth-child(4) {
        order: -1;
      }
      &:nth-child(1) {
        order: 0;
      }
      &:nth-child(5) {
        order: -3;
      }
    }
    &--wrapper {
      width: 100%;
      cursor: pointer;
      transition-timing-function: ease-out;
      transition: 0.3s;
      &--active {
        overflow: hidden;
      }
      &--disabled {
        transition: 0s;
        height: 0;
        overflow: hidden;
        width: 0;
      }
    }
  }
  &__list-item,
  &__subcategory-item {
    width: 100%;
    display: flex;
    align-items: center;
    margin-right: 30px;
    &--paragraph {
      font-weight: 600;
      font-size: 18px;
      line-height: 26px;
      color: #f5f5f5;
      padding: 20px 0;
      width: 100%;
      &--short {
        padding-right: 20px;
      }
    }
    &--icon {
      transform: rotate(270deg);
      margin-left: auto;
      transition: 0.6s;
      &--short {
        margin-right: 15px;
      }
    }
    &--active {
      display: flex;
      flex-direction: row-reverse;
      margin-left: auto;
      // transition: 0.3s;
      svg {
        transform: rotate(90deg);
        margin-right: 20px;
        transition: 0.4s;
      }
    }
    &--disabled {
      display: flex;
      flex-direction: row;
      margin-left: auto;
      overflow: hidden;
      margin-right: -400px;
      height: 0;
      svg {
        transform: rotate(270deg);
        margin-right: 20px;
        transition: 0.4s;
      }
    }
  }
  &__subpage {
    height: 0;
    margin-left: 800px;
    transition: 0.4s;
    width: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
    &--active {
      // overflow-y: auto;
      // transition: 0.4s;
      // height: 100%;
      // max-height: 1000px;
      // margin-left: 0;
      transition: 0.4s;
      margin-left: 0;
      min-height: 500px;
      height: 100%;
      overflow-y: auto;
      padding-bottom: 100px;
    }
    &--all {
      font-size: 16px;
      color: #f5f5f5;
      padding: 20px 0;
      &--disabled {
        display: none;
      }
    }
  }
}
