.order-item {
  color: #262626;
  &__delivery-address {
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    &__content {
      display: none;
    }
    &__label {
      display: flex;
      align-items: center;
      color: #ffa914;
      font-weight: 600;
      font-size: 12px;
      cursor: pointer;
    }
    &--active {
      .svg--chevron {
        transform: rotate(180deg);
      }
      .order-item__delivery-address__content {
        display: flex;
        flex-direction: column;
        cursor: pointer;
      }
    }
  }
  &__wrapper {
    border: 1px solid;
    min-width: 100%;
    border-radius: 8px;
    padding: 23px 17px;
    &--unpaid {
      border-color: #d13239;
    }
    &--paid {
      border-color: #87ce8a;
    }
  }
  &__status {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 8px;
  }
  &__products {
    @media (max-width: 756px) {
    }
  }
  &__product {
    &__wrapper {
      display: grid;
      height: 108px;
      align-items: center;
      margin: 18px 0;
      border-bottom: 1px solid #f3f3f3;
      @media (min-width: 756px) {
        grid-template-columns: 120px auto 15% 15%;
      }
      @media (max-width: 756px) {
        grid-template-areas: 'image title title' 'empty count total';
        position: relative;
        margin-bottom: 10px;
        padding-bottom: 10px;
      }
    }
    &__img {
      max-width: 93px;
      max-height: 78px;
      @media (max-width: 756px) {
        grid-area: image;
        padding-right: 5px;
      }
    }
    &__title {
      font-weight: 600;
      font-size: 14px;
      @media (max-width: 756px) {
        grid-area: title;
      }
    }
    &__count {
      font-weight: 500;
      font-size: 16px;
      @media (max-width: 756px) {
        grid-area: count;
      }
    }
    &__currency {
      text-transform: uppercase;
    }
    &__total {
      font-weight: 600;
      font-size: 20px;
      position: relative;
      &--brutto {
        position: absolute;
        bottom: -15px;
        left: calc(50% - 15px);
        font-weight: 400;
        font-size: 10px;
        color: #212121;
        @media (max-width: 756px) {
          color: #bbbbbb;
        }
      }
      @media (max-width: 756px) {
        grid-area: total;
      }
    }
  }
  &__name {
    color: #ffa914;
    font-weight: 600;
    font-size: 14px;
    height: 21px;
  }
  &__person {
    font-weight: 500;
    font-size: 14px;
    line-height: 147.69%;
    color: black;
    height: 21px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__top {
    margin-bottom: 18px;
    display: flex;
    justify-content: space-between;
    position: relative;
    @media (max-width: 756px) {
     flex-direction: column;
    }
  }
  &__total {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    &__left {
      font-size: 16px;
    }
    &__right {
      font-size: 24px;
      @media (max-width: 756px) {
        font-size: 18px;
      }
    }
  }
  &__delivery {
    display: grid;
    grid-template-columns: [title] 125px [name]auto [amount] auto;
    margin-bottom: 18px;
    margin-top: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid #f3f3f3;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
  }
  &--price {
    text-align: end;
    font-weight: 600;
    font-size: 20px;
    @media (max-width: 756px) {
      font-size: 14px;
    }
  }
}
