.title {
  &__magazine {
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 30px;
  }
  &__modals {
    display: flex;
    justify-content: center;
    font-size: 24px;
    padding: 6px 0;
    line-height: 147.7%;
    padding-bottom: 20px;
    font-weight: 600;
  }
}
