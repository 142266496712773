.popular-brands {
  margin-bottom: 150px;
  &--name {
    margin-bottom: 42px;
    font-weight: 700;
    font-size: 18px;
    color: #363636;
    margin-top: 50px;
  }
  &__list {
    margin-left: 40px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    max-height: 50vh;
    width: fit-content;
    min-width: 100%;
    @media (max-width: 756px) {
      overflow-x: auto;
      padding-left: 40px;
      margin-left: 0px;
    }
  }
}
