.add {
  &__photos {
    display: flex;
    flex-wrap: wrap;
    margin-left: -5px;
    margin-top: 20px;
    // margin-bottom: 30px;
    position: relative;
    flex-grow: 1;

    &--categoryManag {
      margin-top: 0;
      width: 100%;
      display: flex;
      @media (max-width: 756px) {
        flex-direction: column;
      }
    }

    @media (max-width: 756px) {
      justify-content: center;
      margin-top: 3px;
      margin-bottom: 5px;
    }
    &--error {
      margin-left: 7px;
      font-size: 14px;
      color: #fc4e4e;
    }
    &--error2 {
      margin-left: 0;
      margin-top: 5px;
      font-size: 12px;
    }
  }
  &__photo {
    &--catManage {
      position: relative;
      width: 100%;
      margin-left: 20px;
      @media (max-width: 756px) {
        margin-left: 0;
        height: 36px;
      }
    }
    &--catManag {
      position: relative;
      cursor: pointer;
      flex-shrink: 0;
      &--name {
        background-color: #f5f5f5;
        border-radius: 4px;
        width: 100%;
        height: 100%;
        position: absolute;
        display: flex;
        align-items: center;
        padding-right: 24px;
        padding-left: 10px;
        color: #bdbdbd;
        font-size: 12px;
        p {
          display: block; /* or inline-block */
          text-overflow: ellipsis;
          overflow: hidden;
          word-wrap: no-wrap;
          max-width: 120px;
          white-space: nowrap;
          line-height: 18px;
          @media (max-width: 756px) {
            max-width: 250px;
          }
        }
      }
      @media (max-width: 756px) {
        width: 100%;
        margin-bottom: 20px;
      }
    }
    width: 148px;
    height: 148px;
    background-color: #eeeeee;
    margin: 8px 12px;
    align-items: center;
    display: flex;
    justify-content: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    border-radius: 4px;
    cursor: pointer;
    position: relative;
    @media (max-width: 405px) {
      width: 130px;
      height: 130px;
    }
    &--img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &--displayed {
      position: relative;
    }
    &--input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      color: transparent;
      opacity: 0;
      cursor: pointer;
    }

    &--remove {
      position: absolute;
      width: 28px;
      height: 28px;
      top: 0;
      right: 0;
      z-index: 999;

      &__wrapper-catManag {
        position: absolute;
        right: 9px;
        top: 9px;
        z-index: 999;
        cursor: pointer;
        svg {
          width: 11px;
          height: 11px;
        }
        svg path,
        svg line {
          stroke: #bdbdbd;
        }
      }
      &__wrapper {
        position: absolute;
        width: 28px;
        height: 28px;
        border-radius: 50%;
        background-color: #ffa914;
        top: -10px;
        right: -8px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        z-index: 99;
        svg {
          width: 14px;
        }
      }
    }
  }
}
