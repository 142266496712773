.payments {
  min-height: calc(100vh - 45px);
  align-content: flex-start;
  justify-content: center;
  display: flex;
  padding-top: 77px;
  @media (max-width: 756px) {
    padding-top: 70px;
  }
  &__content {
    .descript {
      text-align: center;
      font-weight: 500;
      font-size: 18px;
      line-height: 150%;
      span {
        color: #ffa914;
      }
      &--failure {
        max-width: 262px;
        font-size: 14px;
      }
    }
    .svg {
      width: 15px;
      height: 15px;
    }
  }
  &__image {
    &--wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &--content {
      display: flex;
      justify-content: center;
      flex-direction: column;
      .failure {
        margin: 20px 0;
      }
      .btn {
        max-width: 271px;
        width: 100%;
        margin: 0 auto;
        margin-top: 20px;
      }
    }
  }
  &--canceled {
    &__buttons {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      .or {
        margin-top: 15px;
        font-size: 10px;
        color: #c4c4c4;
      }
    }
  }
}
