.badge {
  font-size: 14px;
  font-weight: 500;
  line-height: 147%;
  color: #fff;
  padding: 3px 18px;
  border-radius: 13px;
  @media (max-width: 756px) {
    &::first-letter {
      font-size: 14px;
      line-height: 8px;
      width: 24px;
      height: 24px;
      text-align: center;
    }
    font-size: 0px;
    padding: 12px;
    border-radius: 50%;
    min-width: 30px;
    min-height: 30px;
    max-width: 30px;
    max-height: 30px;
    justify-content: center;
    align-items: center;
    display: flex;
    &--label{
      max-width: 15px;
      max-height: 15px;
      &::first-letter {
        font-size: 14px;
        line-height: 8px;
        width: 24px;
        height: 24px;
        text-align: center;
      }
    }
  }
  &--CLIENT {
    background-color: #a5c9ff;
  }
  &--COORDINATOR {
    background-color: #87ce8a;
  }
  &--PARTNER {
    background-color: #ffa914;
  }
  &--UNACTIVE {
    background: rgba(252, 78, 78, 0.6);
  }
  &--SUSPENDED {
    background-color: #929292;
  }
  &--STOREMAN {
    background-color: #ab44fc;
  }
}
