.delivery-options {
  min-height: 100vh;
  &__btn {
    @media (max-width: 756px) {
      margin-top: 30px;
    }
  }
  .heading {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-top: 69px;
  }
  &__languages {
    margin-top: 29px;
  }
  &__menu {
    display: flex;
    height: 26px;

    &--item {
      font-size: 16px;
      color: #000000;
      font-weight: 300;
      line-height: 24px;
      cursor: pointer;
      display: flex !important;
      margin: 0 5px;
      &:first-child {
        margin-left: 0;
      }
      .label {
        padding: 0 15px;
      }
    }
    &--active {
      .label {
        font-weight: 500;
        border-bottom: 1.5px solid black;
      }
    }
  }
  .content-top {
    margin-top: 36px;
    display: flex;
    padding: 20px;
    border-bottom: 1px solid #eeeeee;
    display: grid;
    grid-template-columns: 2fr 2fr 2fr 2fr 1fr;
    .paragraph {
      text-transform: capitalize;
      font-size: 14px;
      line-height: 21px;
      font-weight: 400;
    }
    .add {
      display: flex;
      cursor: pointer;
      justify-content: flex-end;
    }
  }
}
