.watched-products {
  width: 100%;
  // height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 756px) {
    height: 100%;
  }
  &--item {
    margin: 8px 0;
    display: flex;
    align-content: center;
    padding: 8px;

    .product-list-item__photography {
      width: 171px;
      height: 143px;
    }
  }
  .list {
    margin-top: 50px;
    width: 100%;
    height: calc(100% - 100px);
    overflow: hidden;
    @media (max-width: 756px) {
      width: 100%;
      height: 100%;
      margin: 0;
    }
    &__content {
      overflow: auto;
      height: auto;
      height: 100%;
      padding-bottom: 70px;

      .list {
        opacity: 1;
        transform: translateX(0);
      }

      .item-exit {
        opacity: 1;
      }

      .item-exit-active {
        opacity: 0;
        transition: opacity 500ms ease-in;
      }
    }
    .title {
      width: 100%;
      font-size: 18px;
      font-weight: 500;
      display: flex;
      padding-bottom: 36px;
      border-bottom: 1px solid #dddddd;
      @media (max-width: 756px) {
        margin-top: 40px;
      }
    }
  }
  .empty {
    font-size: 14px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
    @media (max-width: 756px) {
      margin-top: 60px;
      align-items: flex-start;
    }
    &--image {
      max-width: 275px;
      @media (max-width: 756px) {
        margin: 0 auto;
      }
    }
    &--header {
      margin-top: 40px;
      font-size: 18px;
      font-weight: 500;
      line-height: 27px;
    }
    &--descript {
      max-width: 572px;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      margin-top: 9px;
    }
  }
}

@keyframes myAnimation {
  0% {
    opacity: 1;
    transform: rotateX(90deg);
  }
  50% {
    opacity: 0.5;
    transform: rotateX(0deg);
  }
  100% {
    display: none;
    opacity: 0;
    transform: rotateX(90deg);
  }
}
