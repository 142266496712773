.country-select--disabled {
  pointer-events: none;
  .react-select__indicators {
    div {
      &:first-child {
        display: none;
      }
    }
    .react-select__dropdown-indicator {
      // opacity: 0.3;
      path{
        fill: #C4C4C4;
      }
    }
  }
}
.search-parts {
  width: 100%;
  padding: 42px 25px;
  background-color: #fff8f1;
  border-radius: 15px;
  display: flex;
  margin-top: 32px;
  @media (max-width: 756px) {
    flex-wrap: wrap;
    padding: 42px 15px;
  }
  @media (max-width: 550px) {
    padding-bottom: 25px;
  }
  &--heading {
    max-width: 290px;
  }
  .regular-field__label-error {
    position: absolute;
  }
  .search-parts__country {
    width: 195px;
    @media (max-width: 756px) {
      width: 100%;
      margin: 16px 0px;
    }
  }
  &__loupCategory {
    display: flex;
    width: 100%;
    @media (max-width: 756px) {
      display: none;
    }
    .react-select__value-container {
      border-left: 1px solid #eeeeee;
    }
    .regular-field__icon--wrapper {
      margin-right: 0 !important;
      width: 100%;
    }
    .regular-field__input input {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    .react-select__control {
      height: 43px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
  &__country {
    .select-field__label {
      color: #ffa914 !important;
      top: -5px !important;
    }
    .select-field__error-label {
      position: absolute;
    }
    .select-field {
      margin: 0 !important;
    }
  }
  &__form {
    font-family: 'Poppins' !important;
    width: 100%;
    margin-left: 38px;
    ::placeholder {
      color: #b0b0b0 !important;
      input {
        font-family: 'Poppins' !important;
      }
    }
    .react-select__placeholder {
      color: #b0b0b0 !important;
    }
    @media (max-width: 1024px) {
      margin-left: 20px;
    }
    @media (max-width: 756px) {
      margin-left: 0;
    }
    &--wrapper {
      display: flex;
    }
    &--half {
      width: calc(50% - 27px);
      justify-content: space-between;
      align-items: center;
      @media (max-width: 756px) {
        width: 100%;
      }
      .select-field__wrapper {
        width: calc(50% - 10px);
        @media (max-width: 756px) {
          width: 100%;
        }
      }
    }
  }
  &--top {
    display: flex;
    align-items: center;
    .regular-field__icon--wrapper:first-child {
      // width: 195px;
      @media (min-width: 756px) {
        margin-right: 10px;
      }
      .react-select__value-container{
        padding: 9px 0 9px 15px;
      }
    }
    .regular-field__icon--wrapper:nth-child(2),
    .regular-field {
      width: 100%;
    }
    // @media (max-width: 756px) {
    //   display: none;
    // }
  }
  &--mid {
    justify-content: space-between;
    .select-field__wrapper {
      width: calc(50% - 27px);
      @media (max-width: 1024px) {
        width: calc(50% - 7px);
      }
      @media (max-width: 756px) {
        width: 100%;
      }
    }
    @media (max-width: 550px) {
      display: flex;
      flex-wrap: wrap;
    }
  }
  &--bot {
    display: flex;
    justify-content: space-between;
    @media (max-width: 550px) {
      display: flex;
      flex-wrap: wrap;
    }
  }
  &--years {
    display: flex;
    justify-content: space-between;
    width: 50%;
    .regular-field {
      width: 40%;
    }
  }
  &--buttons {
    display: flex;
    justify-content: space-between;
    width: calc(50% - 27px);
    align-items: center;
    @media (max-width: 1024px) {
      width: calc(50% - 7px);
    }
    @media (max-width: 550px) {
      width: 100%;
      margin-top: 20px;
    }
    .btn {
      width: calc(50% - 10px);
      flex-shrink: 0;
      white-space: nowrap;
      @media (max-width: 1024px) {
        width: calc(50% - 2px);
      }
    }
  }
}
