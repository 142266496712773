.advertisement-localization {
  &__wrapper {
    .regular-field {
      input {
        font-size: 14px;
        &::placeholder {
          text-decoration: unset;
          color: #cbcbcb;
        }
      }
      label {
        color: #272727;
      }
    }
  }
  &--error {
    font-size: 14px;
    color: #fc4e4e;
    margin-top: 25px;
    margin-left: 5px;
  }
  &__gray {
    display: flex;
    justify-content: space-between;
    color: #929292;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.022em;
    margin-top: 24px;
    margin-bottom: 40px;
    @media (max-width: 756px) {
      flex-direction: column;
    }
    p {
      &:first-child {
        width: 60%;
        @media (max-width: 756px) {
          width: 100%;
        }
      }
      // &:last-child {
      //   width: 40%;
      //   @media (max-width: 756px) {
      //     width: 100%;
      //   }
      // }
    }
  }
  &__item {
    display: flex;
    align-items: flex-end;
    margin: 15px 0;
    &--label {
      display: block;
      width: 60%;
      position: relative;
      padding-left: 40px;
      font-size: 14px;
      letter-spacing: 0.022em;
      margin-top: 10px;
      cursor: pointer;
      @media (max-width: 756px) {
        padding-right: 8px;
      }
      &--active {
        &:after {
          background-color: #ffa914;
          border: 1px solid #ffa914;
        }
        &:before {
          content: '';
          top: 1px;
          left: 11px;
          width: 4px;
          height: 7px;
          border: solid white;
          border-width: 0 3px 3px 0;
          transform: rotate(45deg);
          position: absolute;
          border-radius: 1px;
          z-index: 9;
        }
      }
      &:after {
        content: '';
        width: 16px;
        height: 16px;
        border-radius: 3px;
        position: absolute;
        border: 1px solid #dddddd;
        transition: 0.3s;
        left: 5px;
        top: -2px;
      }
    }
    &--price-wrapper {
      position: relative;
      margin-left: auto;
      display: flex;
    }
    &--currency {
      margin-left: 5px;
      font-size: 14px;
    }
    &--price {
      max-width: 126px;
    }
  }
}
