.page404 {
  &__wrapper {
    width: 100%;
    height: calc(100vh - 50px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    @media (max-width: 756px) {
      padding-top: 60px;
    }
  }
  &__content {
    width: 100%;
    height: 100%;
    padding-top: 137px;
    margin-bottom: 200px;
    @media (max-width: 756px) {
      padding-top: 60px;
    }
  }
  &--heading {
    font-size: 96px;
    color: #ffa914;
    text-align: center;
    line-height: 150%;
  }
  &--descript {
    text-align: center;
    margin-top: 8px;
    font-size: 24px;
    line-height: 150%;
    font-weight: 400;
  }
  &__image {
    &--wrapper {
      display: flex;
      justify-content: center;
      margin-top: -90px;
    }
  }
  &__searching {
    display: flex;
    justify-content: center;
    margin-top: -40px;
    @media (max-width: 756px) {
      margin-top: -90px;
    }
    &--content {
      max-width: 753px;
      width: 100%;
      display: flex;
      align-items: center;
      @media (max-width: 756px) {
        flex-direction: column;
      }
    }
    .search-field {
      margin: 0;
    }
    &--input {
      margin-right: 25px;
      max-width: 483px;
      width: 100%;
      @media (max-width: 756px) {
        max-width: 100%;
        margin-right: 0;
      }
      .d {
        fill: #ffa914;
      }
      input {
        background-color: transparent;
        border-radius: 4px;
        border: 1px solid #eeeeee;
      }
    }
    &--paragraph {
      font-size: 16px;
      font-weight: 400;
      display: flex;
      flex-shrink: 0;
      @media (max-width: 756px) {
        margin-top: 28px;
      }
    }
    &--bold {
      margin-left: 10px;
      font-weight: 600;
      color: #ffa914;
      cursor: pointer;
    }
  }
}
