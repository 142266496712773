@import './_reset';
@import './_variables';

body {
  font-family: Poppins !important;
}
.none {
  display: none;
}
.main-wrapper {
  display: flex;
  justify-content: center;
  overflow: hidden;
  flex-wrap: wrap;
  overflow-y: auto;
}
.page {
  &__inner {
    max-width: 1038px;
    width: calc(100% - 42px);
    padding-top: 45px;
    // &--fullWidth {
    //   max-width: 100%;
    //   width: 100%;
    //   display: flex;
    //   justify-content: center;
    //   // .chat--footer {
    //   //   position: fixed;
    //   //   bottom: 470px;
    //   // }
    // }
  }
}

.currency {
  text-transform: uppercase;
}

.title {
  font-size: 24px;
  padding: 6px 0;
  line-height: 147.7%;
  &--orange {
    color: #ffa914;
    font-weight: 700;
    strong {
      color: #000;
    }
  }
}
.descript {
  font-size: 12px;
  line-height: 18px;
  padding: 8px 0;
  &--orange {
    color: #ffa914;
  }
}
