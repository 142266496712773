.modal {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  min-height: 100vh;
  backdrop-filter: blur(1px);
  background-color: rgba(0, 0, 0, 0.26);

  .adding {
    .select-field__error-label {
      font-size: 12px;
      margin-left: 0;
      font-weight: 400;
    }
  }
  .editUser {
    .btn:first-child {
      margin-right: 20px;
      @media (max-width: 756px) {
        margin-right: 0;
      }
    }
  }

  .white {
    background-color: white;
    input {
      border: 0.5px solid #dddddd !important;
    }
    .select-field {
      border: 0.5px solid #dddddd !important;
      border-radius: 4px;
      input {
        border: unset !important;
      }
    }
  }
  &__close {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
    &--icon {
      width: 14px;
      height: 14px;
      line,
      path {
        stroke: #929292;
      }
    }
  }
  &__inner {
    overflow: auto;
    padding: 15px 30px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    width: unset;
    height: unset;
    min-width: 374px;
    border-radius: 16px;
    &--filters {
      overflow: visible;
    }
    &--searching {
      padding: unset;
      top: 30%;
      max-width: 1038px;
      width: 100%;
      .modal__close {
        display: none;
      }
    }
    &--backOff {
      padding: 50px;
      .modal__content {
        .backOff__content {
          padding-bottom: 20px;
          text-align: center;
          line-height: 150%;
        }
      }
    }
    &--delivery {
      padding: 32px 75px;
      max-width: 480px;
      @media (max-width: 500px) {
        padding: 20px;
      }
    }
    &--edit {
      width: 600px;
      display: flex;
      justify-content: center;
      padding: 30px;
      .modal__content {
        width: 300px;
      }
      @media (max-width: 756px) {
        width: unset;
      }
    }
    &--delete {
      padding: 50px 80px;
      text-align: center;
      line-height: 150%;
      @media (max-width: 756px) {
        padding: 40px 40px;
      }
    }
    &--partner {
      background-color: #f5f5f5;
      padding: 46px 91px 60px 90px;
      max-height: calc(100vh - 100px);
      max-width: 640px;
      width: 100%;
      @media (max-width: 756px) {
        max-width: unset;
        width: calc(100% - 40px);
        padding: 44px 17px;
        min-width: unset;
      }
    }
    &--basic {
      background-color: #f5f5f5;
      padding: 46px 41px 60px 40px;
      max-height: calc(100vh - 98px);
      max-width: 482px;
      width: 100%;
      @media (max-width: 756px) {
        max-width: unset;
        width: calc(100% - 40px);
        padding: 44px 17px;
        min-width: unset;
      }
    }
    &--cancel {
      padding: 40px 130px 63px 125px;
      min-width: 683px;
      @media (max-width: 756px) {
        min-width: calc(100% - 30px);
        padding: 29px 21px;
      }
    }
    &--country {
      padding: 40px 60px !important;
      .modal__close {
        display: none;
      }
      .btn--wrapper {
        width: 100%;
        display: flex;
        justify-content: flex-end;
      }
      .btn--orange {
        padding: 8px 60px;
      }
      .select-field__input {
        border: 1px solid #dddddd !important;
        border-radius: 4px;
        .svg path {
          fill: #dddddd;
        }
      }
    }
  }
  &__heading {
    font-size: 22px;
    font-weight: 600;
    width: 100%;
    margin-bottom: 31px;
    &--center {
      text-align: center;
    }
    @media (max-width: 756px) {
      font-size: 18px;
      margin-bottom: 23px;
    }
  }

  &__content {
    &--deleteMargin {
      margin-top: 50px;
      margin-bottom: 50px;
      display: flex;
      justify-content: center;
    }
  }

  &__description {
    font-size: 18px;
    line-height: 147.69%;
    @media (max-width: 756px) {
      font-size: 14px;
    }
    &--center {
      text-align: center;
    }
    &--cancel {
      width: 428px;
      @media (max-width: 756px) {
        width: auto;
      }
    }
  }
}

@media (max-width: 755px) {
  .modal__transition-enter {
    .modal__inner {
      transform: translateY(450px);
      left: 0;
      right: 0;
    }
  }

  .modal__transition-enter-active {
    .modal__inner {
      transform: translateY(0);
      transition: transform 300ms;
    }
  }

  .modal__transition-exit {
    .modal__inner {
      transform: translateY(0);
    }
  }

  .modal__transition-exit-active {
    .modal__inner {
      transform: translateY(450px);
      transition: transform 300ms;
      left: 0;
      right: 0;
    }
  }
}

.cancel {
  .react-select__control {
    border: 0.5px solid #bdbdbd;
    border-radius: 4px;
  }
}
