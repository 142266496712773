.products-filters {
  display: flex;
  margin: 22px 0;
  flex-wrap: wrap;
  .single-filter {
    margin: 5px;
    &--label {
      text-transform: lowercase;
    }
  }
}
