.single-filter {
  background-color: #f5f5f5;
  padding: 8px 33px 8px 13px;
  position: relative;
  border-radius: 4px;
  margin: 0 3px;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:after {
    content: '';
    width: 16px;
    height: 16px;
    border-radius: 3px;
    position: absolute;
    border: 1px solid #dddddd;
    transition: 0.3s;
    right: 5px;
    top: 7px;
  }
  &--checked {
    &:after {
      background-color: #ffa914;
      border: 1px solid #ffa914;
    }
    &:before {
      content: '';
      top: 9px;
      right: 10px;
      width: 4px;
      height: 7px;
      border: solid white;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg);
      position: absolute;
      border-radius: 1px;
      z-index: 9;
    }
  }
  &--label {
    font-size: 10px;
    color: #272727;
    line-height: 15px;
  }
  &--image {
    height: 10px;
    width: auto;
    margin-left: 10px;
  }
}
