.partnerProgram {
  width: 100%;
  &__wrapper {
    width: 100%;
    // position: relative;
    .header {
      align-items: center;
      justify-content: center;
      display: flex;
      flex-wrap: wrap;
      left: 0;
      top: 45px;
      position: absolute;
      width: 100vw;
      max-height: 500px;
      height: 100%;
    }
  }
  &__error {
    margin-top: 10px;
  }

  &__header {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 100px;
    position: absolute;
    top: 0;
    &--heading {
      color: #ffa914;
      h1 {
        font-size: 36px;
        font-weight: 700;
        line-height: 54px;
        @media (max-width: 756px) {
          font-size: 28px;
        }
      }
      h2 {
        font-size: 24px;
        font-weight: 600;
        margin-top: 5px;
        @media (max-width: 756px) {
          font-size: 18px;
        }
      }
      p {
        color: #ffffff;
        font-size: 18px;
        margin-top: 28px;
        @media (max-width: 756px) {
          font-size: 14px;
        }
      }
      svg {
        margin-left: 8px;
      }
    }
    &--img {
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      @media (max-width: 1100px) {
        width: unset;
      }
      @media (max-width: 756px) {
        width: 100%;
        max-height: 500px;
      }
    }
    &--heading {
      position: relative;
      z-index: 1;
      max-width: 1038px;
      width: calc(100% - 42px);
    }
  }
  &__arrow {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 50px;
  }
  &__main {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  &__premium {
    max-width: 1038px;
    background-color: #f5f5f5;
    padding: 44px 48px 64px 48px;
    border-radius: 16px;
    margin: 450px auto 0 auto;
    z-index: 9;
    // @media (max-width: 1100px) {
    //   margin: -100px 30px 0 30px;
    // }
    // @media (max-width: 756px) {
    //   margin: -100px 21px 0 21px;
    //   padding: 44px 30px 64px 30px;
    // }
    &--heading {
      text-align: center;
      font-size: 24px;
      font-weight: 700;
      line-height: 147.69%;
      margin-bottom: 31px;
      @media (max-width: 756px) {
        font-size: 20px;
      }
    }
    &--paragraph {
      font-size: 18px;
      font-weight: 400;
      line-height: 164.19%;
      @media (max-width: 756px) {
        font-size: 14px;
      }
    }
    &--tiles {
      margin-top: 48px;
      display: flex;
      min-height: 284px;
      justify-content: center;
      @media (max-width: 900px) {
        flex-wrap: wrap;
      }
    }
    &__tile {
      position: relative;
      max-width: 370px;
      background-color: #ffffff;
      padding: 24px 47px 40px 47px;
      border-radius: 16px;
      display: flex;
      flex-direction: column;
      .netto {
        font-size: 12px !important;
        text-transform: uppercase;
      }
      &:first-child {
        margin-right: 24px;
        @media (max-width: 900px) {
          margin-right: 0;
          margin-bottom: 34px;
        }
      }
      &--pr {
        background-color: #ffa914;
      }
      .icon {
        position: absolute;
        top: -7px;
        right: -7px;
        svg {
          height: 43px;
          width: 43px;
        }
      }

      &--heading {
        font-size: 24px;
        font-weight: 500;
        line-height: 33px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #000000;
        text-transform: uppercase;
        font-weight: 700;
        svg {
          margin-left: 10px;
        }
        @media (max-width: 756px) {
          font-size: 18px;
        }
      }
      &--price {
        font-size: 48px;
        color: #ffa914;
        font-weight: 700;
        text-align: center;
        // line-height: 147.69%;
        margin: 18px 0;
        &--pr {
          color: #fff;
        }
        &--pr {
          font-size: 20px;
        }
      }
      &--paragraph {
        text-align: center;
        margin-top: auto;
        margin-bottom: 10px;
      }
      &--button {
        margin-top: auto;
        display: flex;
        justify-content: center;
      }
    }
  }
  &__benefits {
    max-width: 1036px;
    margin: 108px auto 90px auto;
    @media (max-width: 1100px) {
      margin: 108px 30px 90px 30px;
    }
    @media (max-width: 756px) {
      margin: 81px 21px 60px 21px;
    }
    &--heading {
      font-size: 36px;
      line-height: 147.69%;
      margin-bottom: 93px;
      @media (max-width: 756px) {
        font-size: 24px;
        margin-bottom: 70px;
      }
    }
    &__single {
      display: flex;
      align-items: center;
      margin-right: 40px;
      margin-bottom: 80px;
      @media (max-width: 756px) {
        margin-right: 20px;
        margin-left: 30px;
      }
    }
    &--paragraph {
      max-width: 140px;
      line-height: 147.69%;
      margin-left: 30px;
    }
    &--image {
      margin: 0 20px;
      flex-shrink: 0;
    }
    &--lists {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }
  &__howitworks {
    max-width: 1036px;
    margin: 0 auto;
    @media (max-width: 900px) {
      margin: 0 21px;
    }

    &--single {
      display: flex;
      margin-bottom: 128px;
      @media (max-width: 756px) {
        flex-direction: column;
        justify-content: center;
        margin-bottom: 61px;
      }
      &:nth-child(even) {
        flex-direction: row-reverse;
        @media (max-width: 756px) {
          flex-direction: column;
        }
        .partnerProgram__info {
          margin-right: 45px;
          @media (max-width: 756px) {
            margin-right: 0;
          }
        }
      }
      &:nth-child(odd) {
        .partnerProgram__info {
          margin-left: 45px;
          @media (max-width: 756px) {
            margin-left: 0;
          }
        }
      }
    }
    &__image {
      @media (max-width: 756px) {
        display: flex;
        justify-content: center;
      }
    }
    &--heading {
      font-size: 36px;
      font-weight: 600;
      text-align: center;
      margin-bottom: 108px;
      @media (max-width: 756px) {
        margin-bottom: 51px;
      }
    }
    &__info {
      &--step {
        margin-top: 5px;
        font-size: 24px;
        font-weight: 500;
        margin-bottom: 20px;
        @media (max-width: 756px) {
          text-align: center;
          margin-top: 20px;
          margin-bottom: 25px;
        }
      }
      &--paragraph {
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
      }
    }
  }

  &__modal {
    &--button-hp {
      margin-top: 20px;
      display: flex;
      justify-content: center;
    }
    &--descript {
      max-width: 330px;
      &--wrapper {
        display: flex;
        justify-content: center;
        width: 100%;
      }
    }
    &--form {
      display: flex;
      justify-content: center;
    }
    &--content {
      max-width: 330px;
      .regular-field__label {
        color: #000;
        text-transform: uppercase;
      }
    }
    &__nip-container {
      display: flex;
      .select-field__wrapper {
        max-width: 112px;
        margin-right: 17px;
      }
      .select-field__label {
        color: #000;
        text-transform: uppercase;
      }
      .svg--chevron path {
        fill: #000;
      }
    }
    &__consent {
      font-size: 14px;
      padding-left: 40px;
      letter-spacing: 0.022em;
      line-height: 21px;
      position: relative;
      cursor: pointer;
      div {
        cursor: pointer;
        display: inline;
        font-weight: 600;
      }

      &:before {
        content: '';
        width: 16px;
        height: 16px;
        background-color: #fff;
        position: absolute;
        left: 0;
        top: 5px;
        border-radius: 3px;
        transition: 0.2s;
      }
      &--active {
        &:after {
          content: '';
          width: 16px;
          height: 16px;
          background-color: #ffa914;
          position: absolute;
          display: block;
          top: 5px;
          left: 0;
          border-radius: 3px;
          z-index: 9;
        }
        &:before {
          content: '';
          top: 8px;
          left: 5px;
          width: 4px;
          height: 6px;
          border: solid white;
          border-width: 0 2.5px 2.5px 0;
          transform: rotate(45deg);
          position: absolute;
          border-radius: 1px;
          z-index: 91;
          background-color: unset;
        }
      }
    }
  }
  &__subsciptions {
    &--wrapper {
      display: flex;
      margin-bottom: 58px;
      margin-top: 20px;
      @media (max-width: 756px) {
        flex-wrap: wrap;
        justify-content: center;
      }
    }
    &__tile {
      max-width: 238px;
      background-color: #fff;
      border-radius: 16px;
      padding: 24px 18px;
      cursor: pointer;
      border: 1px solid #fff;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      min-height: 240px;
      .netto {
        font-size: 12px !important;
      }
      &--pr {
        background-color: #ffa914;
      }
      &:first-child {
        margin-right: 20px;
        @media (max-width: 570px) {
          margin-right: 0;
          margin-bottom: 33px;
        }
      }
      &--premium {
        background-color: #ffa914;
      }
      &--active {
        border: 1px solid #ffa914;
        &:after {
          content: '';
          width: 27px;
          height: 27px;
          border-radius: 50%;
          top: -10px;
          right: -10px;
          position: absolute;
          background-color: #ffa914;
        }
        &:before {
          content: '';
          top: -2px;
          right: -1px;
          width: 4px;
          height: 7px;
          border: solid white;
          border-width: 0 3px 3px 0;
          transform: rotate(45deg);
          position: absolute;
          border-radius: 1px;
          z-index: 9;
        }
      }
    }
    &--heading {
      font-size: 18px;
      font-weight: 500;
      line-height: 130%;
      text-align: center;
      font-weight: 700;
      font-size: 24px;
      display: flex;
      align-items: center;
      svg {
        margin-left: 8px;
        flex-shrink: 0;
      }
    }
    &--price {
      font-size: 44px;
      font-weight: 700;
      color: #ffa914;
      text-align: center;
      margin: 24px 0;
      &--pr {
        color: #fff;
        font-size: 20px;
        line-height: 135%;
      }
    }
    &--paragraph {
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      margin-top: auto;
    }
  }
}

.edit--partner {
  .regular-field__input {
    display: block;
  }
  .select-field__label {
    top: -5px;
  }
  .select-field__error-label {
    font-size: 12px;
    margin-left: 0;
  }
}
