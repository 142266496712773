.photo-slider {
  max-width: 565px;
  position: relative;
  height: 448px;
  &--one-photo {
    height: 285px;
  }
  @media (max-width: 756px) {
    margin-left: auto;
    margin-right: auto;
  }
  &__item {
    height: 400px;
    width: 100%;
    // background-color: gray;
    max-height: 448px;
    position: relative;
    @media (max-width: 756px) {
      background-color: transparent;
    }
  }
  &__image {
    display: block;
    height: 100%;
    width: 100%;
    object-fit: contain !important;
    &--number {
      position: absolute;
      bottom: 10px;
      left: calc(50% - 10px);
      right: 50%;
      font-size: 12px;
      line-height: 18px;
      font-weight: 700;
      color: #fff;
      display: flex;
      letter-spacing: 3px;
    }
    .skeleton-thumbnail {
      &__wrapper span {
        margin-top: 10px;
        display: flex;
      }
      &__item:nth-child(n + 2) {
        margin-left: 10px;
      }
    }
  }
  &__element {
    width: 300px;
    height: 300px;
  }
  &__icon-image {
    width: 100% !important;
    height: 100%;
    display: block;
    border-radius: 6px !important;
    object-fit: scale-down;
  }
  &--count {
    position: absolute;
    right: 65px;
    bottom: -21px;
    font-weight: 700;
    font-size: 12px;
  }

  .slick-next {
    right: -30px !important;
    z-index: 9;
    top: 40%;
    @media (max-width: 756px) {
      right: 20px !important;
      path {
        fill: #ffffff;
      }
    }
  }
  .slick-prev {
    left: -30px !important;
    z-index: 9;
    top: 40%;
    @media (max-width: 756px) {
      left: 20px !important;
      path {
        fill: #ffffff;
      }
    }
  }
  .slick-next,
  .slick-prev {
    &:before {
      content: '';
    }
  }
  .slick-dots {
    position: relative;
    display: flex !important;
    justify-content: flex-start;
    margin-top: -10px;
    li {
      border-radius: 8px;
      height: 87px;
      width: 110px;
      border: 2px solid transparent;
      margin: 3px;
      div {
        width: 100%;
        height: 100%;
      }
      &:nth-child(n + 5) {
        display: none;
      }
    }
    .slick-active {
      border: 2px solid #000;
      transition: 0.4s;
      box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.25);
    }
  }
}
