.help-chat {
  &__archive {
    @media (max-width: 1024px) {
      display: none;
    }
    cursor: pointer;
    background-color: #f5f5f5;
    border-radius: 4px;
    padding: 8px 18px;
    font-size: 12px;
    position: absolute;
    right: 0;
    top: -50px;
    &--disabled{
      pointer-events: none;
      opacity: 0.3;
    }
  }
  &__message {
    .text--client,
    .text--partner {
      border-top-left-radius: 4px;
      border-bottom-right-radius: 16px;
    }

    @media (max-width: 1024px) {
      position: relative;
      &--right {
        margin-left: 0 !important;
      }
      &--left {
        margin-right: 0 !important;
      }
    }
    display: flex;
    position: relative;
    justify-content: space-between;
    margin-top: 5px;
    &--right {
      margin-left: 20px;
      justify-content: flex-end;
    }
    &--left {
      margin-right: 20px;
      justify-content: flex-start;
    }
    .text {
      @media (max-width: 1024px) {
        // width: 80%;
      }
      font-size: 14px;
    }
    &__info {
      position: absolute;
      margin-top: 19px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      &--right {
        right: -70px;
      }
      &--left {
        left: -70px;
      }
      &--date {
        position: absolute;
        top: 0px;
        font-size: 10px;
      }
    }
  }
  &__wrapper {
    @media (max-width: 1024px) {
      min-height: calc(100vh - 50px);
    }
    min-height: calc(90vh - 50px);
    width: 100%;
    display: flex;
    padding-top: 66px;
    flex-wrap: wrap;
    align-content: flex-start;
    @media (max-width: 756px) {
      padding-top: 40px;
    }
  }
  &__top {
    display: flex;
    flex-direction: column;
    width: 100%;
    &__menu {
      position: relative;
      font-style: normal;
      font-weight: 500;
      margin-top: 25px;
      font-size: 11px;
      line-height: 16px;
      &--left {
        display: flex;
        width: 30%;
        position: absolute;
        left: 0;
        top: -7px;
        button {
          cursor: pointer;
          font-weight: 500;
          font-size: 11px;
          line-height: 16px;
          font-family: 'Poppins';
          border: 1px solid #ffa914;
          border-bottom: none;
          padding: 5px 15px;
          border-radius: 16px 16px 0px 0px;
          margin-left: 1px;
          background-color: #ffffff;
        }
        .active {
          background: #fff8f1;
        }
      }
      &--right {
        @media (max-width: 1024px) {
          display: none;
        }
        cursor: pointer;
        background-color: #f5f5f5;
        border-radius: 4px;
        padding: 8px 18px;
        position: absolute;
        right: 0;
        top: -30px;
        @media (max-width: 1024px) {
          font-size: 12px;
          width: max-content;
        }
      }
      &--mobile {
        @media (max-width: 1024px) {
          display: block;
          top: 0;
          position: relative;
        }
      }
    }
  }
  &__menu {
    @media (max-width: 1024px) {
      max-width: 75%;
      justify-content: flex-start;
      .label {
        margin-right: 30px;
      }
    }
    margin-top: 20px;
    display: flex;
    width: 30%;
    justify-content: space-between;
    font-weight: normal;
    .label {
      cursor: pointer;
      font-size: 11px;
      @media (min-width: 768px) {
        font-size: 14px;
      }
    }
    .active {
      border-bottom: 1px solid black;
      padding-bottom: 5px;
    }
  }
  &__content {
    @media (max-width: 1024px) {
      min-height: 89vh;
      height: 89vh;
    }
    min-height: 60vh;
    height: 65vh;
    display: grid;
    width: 100%;
    margin-top: 20px;
    grid-template-columns: [users] 30% [chat] 70%;
    @media (max-width: 1024px) {
      .show {
        left: 0%;
        z-index: 11;
      }
      grid-template-columns: [users] 100% [chat] 100%;
    }
  }
  &__chat {
    width: 80%;
    height: 80%;
    &__top {
      display: grid;
      width: 100%;
      min-height: 68px;
      text-overflow: ellipsis;
      align-items: center;
      grid-template-columns: [back] 10% [icon] 20% [name] 40% [archive] 30%;
      &--arrow {
        transform: rotate(90deg);
        path {
          fill: #272727;
        }
        &__wrapper {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
    &__content {
      @media (max-width: 1024px) {
        padding: 0 91px;
        margin-top: 0;
        width: 100%;
      }
      margin-top: 30px;
      height: 80%;
      width: 80%;
      padding: 0 10%;
      scrollbar-width: none;
      overflow-y: auto;
      overflow-x: visible;
      &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
      }
    }

    &__send {
      display: flex;
      justify-content: space-between;
      @media (max-width: 1024px) {
        margin-bottom: 70px;
      }
      &__wrapper {
        width: 80%;
        max-height: 50px;
      }
      .regular-field {
        width: 100%;
      }
      input {
        border: 1px solid #dddddd;
        border-radius: 4px;
        width: 100%;
        padding: 0 10px;
        margin: 0;
        font-family: 'Poppins';
        height: 48px;
        padding: 10px 22px;
        color: black !important;
        line-height: 150%;
        font-size: 14px;
        &::placeholder {
          text-decoration: none;
          color: #c4c4c4;
          font-family: 'Poppins';
          line-height: 150%;
          font-size: 14px;
        }
      }
    }
    &__wrapper {
      @media (max-width: 1024px) {
        position: fixed;
        left: 100%;
        width: 100%;
        top: 53px;
        transition: 0.3s;
        margin-left: 0;
        min-height: calc(100vh - 45px);
        border-radius: 0;
        height: 85vh;
        max-height: -webkit-fill-available;
      }

      height: 60vh;
      background-color: white;
      transition: 0.3s;
      margin-left: 24px;
      display: flex;
      align-items: center;
      // justify-content: center;
      flex-direction: column;
      border: 1px solid #eeeeee;
      border-radius: 16px;
      padding-bottom: 10px;
      box-sizing: border-box;
      position: relative;
    }
  }
  &__users {
    &__list {
      @media (max-width: 1024px) {
        height: 85vh;
      }
      height: 60vh;
      // padding-top: 10px;
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 100%;
      border: 1px solid #eeeeee;
      border-radius: 0px 16px 16px 16px;
      box-sizing: border-box;
      overflow-y: auto;
      &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
      }
      .active {
        background-color: #f5f5f5;
      }
    }
  }
  &__user {
    &--name {
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 14px;
    }
    &__icons {
      position: relative;
      width: 50px;
      height: 50px;
    }
    &__icon {
      font-style: normal;
      font-weight: 700;
      font-size: 10px;
      line-height: 147.69%;
      width: 29px;
      height: 29px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #ffffff;
      &--chat {
        width: 45px;
        height: 45px;
        font-size: 20px;
        position: relative !important;
        top: 0 !important;
      }
      &:nth-child(2) {
        position: absolute;
        top: 15px;
        left: 15px;
        z-index: 2;
      }
      &--grey:only-child,
      &--blue:only-child,
      &--green:only-child,
      &--coordinator:only-child,
      &--client:only-child,
      &--partner:only-child {
        width: 45px;
        height: 45px;
        font-size: 20px;
      }
      &--unregister,
      &--grey {
        background-color: #929292;
      }
      &--client,
      &--blue {
        background-color: #a5c9ff;
      }
      &--coordinator,
      &--green {
        background-color: #87ce8a;
      }
      &--admin {
        background-color: #272727;
      }
      &--partner {
        background-color: #ffa914;
      }
    }
    align-items: flex-start;
    cursor: pointer;
    width: 100%;
    padding: 10px 10% 10px 10%;
    height: 64px;
    // margin-top: 15px;
    border-bottom: 1px solid #eeeeee;
    display: grid;
    grid-template-columns: [icons] 25% [main] 55% [time] 25%;
    @media (max-width: 1024px) {
      height: 81px;
      align-items: center;
    }
    &__dot {
      margin-left: 10px;
      width: 10px;
      height: 10px;
      background-color: #ffa914;
      border-radius: 50%;
      display: inline-block;
    }
    &__name {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 147.69%;
      color: black;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &__message {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 147.69%;
      color: #929292;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &__time {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 147.69%;
    }
  }
}
