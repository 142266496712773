.switch {
  position: relative;
  display: inline-block;
  width: 120px;
  height: 34px;
  input {
    opacity: 0;
    width: 0;
    height: 0;
    &:checked + .switch__slider + .switch__slider--text {
      color: #272727 !important;
    }
    &:checked + .switch__slider + .switch__slider--text + .switch__slider--text {
      color: white !important;
    }
    &:checked + .switch__slider {
      background-color: white;

      &::before {
        -webkit-transform: translateX(60px);
        -ms-transform: translateX(60px);
        transform: translateX(60px);
      }
    }
  }
  &__slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border: 1px solid #dddddd;
    &::before {
      position: absolute;
      content: '';
      height: 26px;
      width: 52px;
      left: 4px;
      bottom: 3px;
      background-color: #ffa914;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
    &--text {
      position: absolute;
      &:first-of-type {
        left: 19px;
        color: white;
      }
      top: 12px;
      left: 84px;
      color: #272727;
      transition: 0.4s;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 11px;
      cursor: pointer;
    }
    &--round {
      border-radius: 34px;
      &:before {
        border-radius: 30px;
      }
    }
  }
}
