.counter {
  margin-left: 15px;
  margin-right: 70px;
  width: 27px;
  height: 27px;
  background-color: transparent;
  border-radius: 20px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1024px) {
    margin-right: 0;
  }
  @media (max-width: 756px) {
    flex-shrink: 0;
  }

  &--fill {
    background-color: #000000;
  }
}

.advertisements {
  &--heading {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 29px;
  }
  &__wrapper {
    width: 100%;
    min-height: calc(100vh - 50px);
    display: flex;
    padding-top: 66px;
    flex-wrap: wrap;
    align-content: flex-start;
    @media (max-width: 756px) {
      padding-top: 40px;
    }
  }
  &__top {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  &__content {
    width: 100%;
  }

  &__menu {
    display: flex;
    height: 26px;
    @media (max-width: 1024px) {
      justify-content: space-between;
    }
    @media (max-width: 756px) {
      justify-content: unset;
      display: unset;
    }
    &--item {
      font-size: 16px;
      color: #000000;
      font-weight: 300;
      line-height: 24px;
      cursor: pointer;
      display: flex !important;
      &:first-child {
        margin-left: 0;
      }
      @media (max-width: 756px) {
        width: 100%;
      }
    }
    &--active {
      .label {
        font-weight: 500;
        border-bottom: 1.5px solid black;
      }
    }
  }
  &__empty {
    font-size: 14px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 100px 0 100px 0;
    @media (max-width: 756px) {
      margin-top: 60px;
    }
    &--image {
      max-width: 275px;
      @media (max-width: 756px) {
        margin: 0 auto;
      }
    }
    &--header {
      margin-top: 40px;
      font-size: 18px;
      font-weight: 500;
      line-height: 27px;
      text-align: center;
    }
    &--descript {
      max-width: 572px;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      margin-top: 9px;
      @media (max-width: 756px) {
        text-align: center;
      }
    }
    &--button {
      margin-top: 30px;
    }
  }
  &__filters {
    &__wrapper {
      margin-top: 34px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      padding-bottom: 40px;
      margin-bottom: 36px;
      border-bottom: 1px solid #dddddd;
      &--orders {
        justify-content: flex-start;
        @media (max-width: 756px) {
          .btn {
            width: calc(100% - 15px);
          }
        }
      }
      @media (max-width: 756px) {
        justify-content: unset;
        padding-bottom: 34px;
        flex-direction: column;
        button {
          width: calc(50% - 10px);
        }
      }
      .btn {
        margin-left: 8px;
      }
    }
    &--product {
      .product-list-item__right {
        flex-shrink: unset;
      }
    }
    &--category,
    &--sort {
      @media (min-width: 756px) {
        width: 266px;
        margin: 0 8px;
      }
      .select-field {
        border: 0.5px solid #dddddd;
        border-radius: 4px;
        &__label {
          color: #272727;
        }
      }
    }
  }
}
// #search-field--advertisements{
//   .advertisements__filters--mobile{
//     width: 330px;
//     margin:0;
//   }
//   .select-field__label{
//     top:-3px;
//     left:6px;
//   }
// }