.choose-color {
  display: flex;
  flex-wrap: wrap;
  max-width: 550px;
  margin-top: 30px;
  @media (max-width: 756px) {
    max-width: 190px;
    margin: 30px auto 0 auto;
  }
  &__item {
    width: 38px;
    height: 38px;
    border-radius: 50%;
    margin-right: 22px;
    margin-bottom: 22px;
    flex-shrink: 0;
    cursor: pointer;
    position: relative;
    @media (max-width: 756px) {
      margin-right: 35px;
      &:nth-child(3n-3) {
        margin-right: 0;
      }
    }
    &--active {
      &:after {
        content: '';
        width: 21px;
        height: 21px;
        border-radius: 50%;
        background-color: #ffa914;
        position: absolute;
        display: block;
        top: -5px;
        right: -5px;
      }
      &:before {
        content: '';
        top: 0px;
        right: 1.5px;
        width: 4px;
        height: 6px;
        border: solid white;
        border-width: 0 2.5px 2.5px 0;
        transform: rotate(45deg);
        position: absolute;
        border-radius: 1px;
        z-index: 9;
      }
    }
    &--1 {
      background-color: #000000;
    }
    &--2 {
      background-color: #b0b0b0;
    }
    &--3 {
      background-color: #ffffff;
      border: 1px solid #b0b0b0;
    }
    &--4 {
      background-color: #fff8f1;
      border: 1px solid #b0b0b0;
    }
    &--5 {
      background-color: #ffe3c1;
    }
    &--6 {
      background-color: #794900;
    }
    &--7 {
      background-color: #ffdd29;
    }
    &--8 {
      background: linear-gradient(180deg, #b0b0b0 0%, rgba(176, 176, 176, 0) 100%);
    }
    &--9 {
      background: linear-gradient(180deg, #e3b306 0%, rgba(234, 183, 2, 0) 100%);
    }
    &--10 {
      background-color: #ffad32;
    }
    &--11 {
      background-color: #ff5732;
    }
    &--12 {
      background-color: #b40d02;
    }
    &--13 {
      background-color: #ff5baa;
    }
    &--14 {
      background-color: #8514a1;
    }
    &--15 {
      background-color: #0090e1;
    }
    &--16 {
      background-color: #0704aa;
    }
    &--17 {
      background-color: #189404;
    }
    &--18 {
      background-color: #0c5300;
    }
  }
}
