.magazine-wrapper {
  font-family: 'Poppins';
  width: 100%;
  padding: 50px 0 0 0;
  &__header {
    .filters {
      display: flex;
      justify-content: flex-start;
      border-bottom: unset;
      &__search-field {
        width: 483px;
      }
      &__category {
        width: 331px;
      }
      &--orders{
        padding-bottom: 0;
        margin-bottom: 30px;
        border-bottom: 1px solid #dddddd;
      }
    }
  }

  &__content {
    min-height: calc(100vh + 200px);
    .transferStatus {
      display: flex;
      align-items: center;
      gap: 10px;
      &__date {
        font-size: 11px;
        opacity: 0.5;
      }
    }
    .backOff {
      display: flex;
      justify-content: center;
      &__content {
        padding-bottom: 20px;
      }
    }
    .warehouse-management__table {
      &--wrapper {
        height: calc(100vh - 200px);
        .user-management__table__content {
          padding-top: 40px;
          @media (max-width: 756px) {
            margin-top: -80px;
            margin-bottom: 30px;
          }
          &--order-item{
            padding-top: 0px;
            .rt-tr{
              padding: 13px 20px;
              background-color: transparent;
            }
            @media(max-width: 756px){
              padding-top: 65px;
            }
          }
          .rt-table {
            @media (max-width: 756px) {
              margin-bottom: 30px;
            }
          }
        }
        .productTitle {
          font-weight: 600;
          padding: 10px 20px;
          @media (max-width: 756px) {
            font-size: 10px;
          }
        }
      }
      &__heading {
        font-size: 14px;
        padding-left: 20px;
        padding-right: 20px;
        display: flex;
        justify-content: space-between;
        height: 30px;
        border-bottom: 1px solid lightgray;
        &--transfersProduct {
          margin-right: 130px;
        }
        &--transferStatus {
          margin-right: 100px;
        }
        &--category {
          margin-left: 50px;
        }
        &--sellStatus {
          margin-right: 50px;
        }
      }
    }
  }
  .warehouse-management__table--wrapper--order-item{
    height: fit-content;
    .pagination__wrapper{
      margin-bottom: 15px;
    }
  }
}

.productInfo {
  padding: 10px;
  &__label {
    font-size: 12px;
    line-height: 17px;
    opacity: 0.5;
  }
  &__name {
    font-size: 14px;
    line-height: 20px;
  }
}

.edit__fields {
  .regular-field__input {
    input {
      border: 0.5px solid #dddddd;
      border-radius: 4px;
    }
  }
  .select-field {
    .react-select{
      border: 0.5px solid #dddddd;
      border-radius: 4px;
    }
  }
}
