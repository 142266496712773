.product-list-item {
  display: flex;
  border: 1px solid #f3f3f3;
  border-radius: 4px;
  margin: 4.5px 0;

  &__photography {
    width: 191px;
    height: 159px;
    padding: 6px;
    border-radius: 4px;
    flex-shrink: 0.3;
    word-wrap: break-word;
    flex-shrink: 0;
    cursor: pointer;
    &--image {
      border-radius: 4px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &--delete {
    font-size: 12px;
    color: #fc4e4e;
    margin-top: auto;
    display: flex;
    align-items: flex-end;
    cursor: pointer;
    justify-content: flex-end;
    &--icon {
      path {
        fill: #fc4e4e;
      }
      width: 10px;
      margin-right: 8px;
      margin-bottom: -3px;
    }
  }
  &__mid {
    padding: 10px 20px 12px 20px;
    display: flex;
    flex-direction: column;
    &--name {
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      color: #262626;
      text-transform: uppercase;
      cursor: pointer;

      @media (max-width: 1023px) {
        font-size: 12px;
      }
    }
    &--adress {
      margin-top: auto;
    }
    &--katalog {
      font-size: 10px;
      font-weight: 400;
      line-height: 15px;
      color: #b0b0b0;
      margin-top: 6px;
      // max-width: 207px;
    }
    &--breadcrumbs {
      margin-top: 12px;
    }

    &--item {
      padding-top: 10px;
    }

    &--location {
      padding-top: 20px;
      font-size: 10px;
      line-height: 15px;
      font-weight: 400;
    }

    .state-part {
      margin-top: 10px;
    }
  }
  &__right {
    display: flex;
    flex-direction: column;
    padding: 20px 20px 10px 20px;
    margin-left: auto;
    flex-shrink: 0;
    .product-list-item--tooltip {
      bottom: -70px;
    }
    &__buttons {
      display: flex;
      font-size: 14px;
      button {
        margin: 0 15px;
      }
      &--bars {
        position: relative;
        :last-child ::after {
          position: absolute;
          content: '';
          border-left: 1px solid #bdbdbd;
          height: 100%;
          width: 1px;
          right: -15px;
          top: 0;
        }
        :first-child :before {
          position: absolute;
          content: '';
          border-left: 1px solid #bdbdbd;
          height: 100%;
          width: 1px;
          left: -15px;
          top: 0;
        }
      }
    }
    &--bars {
      position: relative;
      ::before {
        position: absolute;
        content: '';
        border-left: 1px solid #bdbdbd;
        height: 100%;
        width: 1px;
        left: -15px;
        top: 0;
      }
    }
    &--price {
      font-weight: 600;
      line-height: 36px;
      font-size: 24px;
      text-align: right;
    }
    .currency {
      text-transform: uppercase;
    }
    &--brutto {
      font-size: 10px;
      font-weight: 400;
      line-height: 15px;
      text-align: right;
      margin-top: -5px;
    }
    &--partner-badge {
      padding-right: 0;
      margin-top: 10px;
      margin-left: auto;
    }
    &--pick-up {
      font-weight: 700;
      font-size: 9px;
      text-transform: uppercase;
      margin-top: auto;
      line-height: 14px;
      text-align: right;
    }
    &--invoice {
      margin-top: 10px;
      margin-bottom: -10px;
      padding: 9px 12px;
    }
    &--button {
      padding: 0;
      margin-left: auto;
      margin-top: auto;
      &--watched {
        padding: 10px 12px;
        svg {
          fill: black;
        }
      }
      &--disabled {
        color: #c4c4c4;
        cursor: auto;
        svg path {
          stroke: #c4c4c4;
        }
      }
    }
  }
  &__settings {
    margin-top: auto;
    display: flex;
    flex-wrap: wrap;
    align-content: space-around;
    margin-top: auto;
    @media (max-width: 765px) {
      position: absolute;
      width: 169px;
      background-color: #fff;
      right: 0;
      top: 40px;
      border: 0.5px solid #dddddd;
      border-radius: 4px;
      flex-direction: column;
      padding: 15px 10px 15px 10px;
      z-index: 999;
    }
    &--wrapper {
      margin-top: auto;
      @media (max-width: 765px) {
        margin-top: unset;
      }
    }
    &--item {
      margin-right: 13px;
      padding: 5px 10px 5px 0;
      border-right: 1px solid #bdbdbd;
      color: #000;
      font-size: 14px;
      cursor: pointer;
      font-weight: 500;
      @media (max-width: 765px) {
        border-right: 0;
        font-weight: 400;
        margin: 6px 0;
      }
      &:last-child {
        border-right: 0;
        margin-right: 0;
        padding-right: 0;
        color: #fc4e4e;
      }
    }
  }
}

.product-list-item-mobile {
  &__wrapper {
    border-bottom: 1px solid #eaeaea;
    padding: 11px 0;
    display: flex;
    position: relative;
    .partner-badge {
      margin: 6px 0;
    }
  }
  &__photography {
    flex-shrink: 0;
    &--settings {
      width: 112px;
      height: 120px;
      flex-shrink: 0;
    }
  }

  &--watched {
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    &--disabled {
      path {
        stroke: #c4c4c4;
      }
    }
  }
  &--price {
    margin: auto 0;
    &--label {
      text-align: left;
      font-size: 18px;
    }
  }
  &--name {
    padding-right: 5px;
    &--wrapper {
      display: flex;
      justify-content: space-between;
    }
  }
  &__right {
    padding: 10px;
    padding-right: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-shrink: 0.5;
    padding-left: 20px;
    &--brutto {
      display: flex;
      color: #bbbbbb;
    }

    &--bottom {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .state-part {
        margin-left: 5px;
      }
    }
  }
  &__icon {
    &--watched {
      fill: black;
      cursor: pointer;
    }
  }
}
