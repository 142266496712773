.single-product {
  margin: 50px 0;
  display: flex;
  @media (max-width: 1100px) {
    .single-tile {
      width: 100%;
    }
  }
  @media (max-width: 756px) {
    display: block;
    margin-top: 30px;
  }
  &__btn {
    &--wrapper {
      display: flex;
      width: 100%;
      margin-top: 22px;
      justify-content: space-between;
      p {
        font-size: 14px;
      }
      .btn--orange-border {
        width: 132px;
        height: 40px;
      }
      .btn--orange {
        width: 179px;
        height: 40px;
      }
    }
  }
  &__country-popup {
    &--heading {
      max-width: 451px;
      line-height: 150%;
    }
    .select {
      display: none;
    }
  }
  &__content {
    margin-top: 17px;
    display: grid;
    grid-template-columns: minmax(100px, 2fr) 1fr;
    grid-auto-rows: minmax(min-content, max-content);
    column-gap: 26px;
    .single-tile {
      width: 100%;
      &--double {
        @media (min-width: 756px) {
          margin-top: -13px !important;
        }
        @media (max-width: 1000px) {
          width: calc(100% + 356px);
        }
        @media (max-width: 756px) {
          width: 100%;
        }
      }
    }
    @media (max-width: 1100px) {
      width: 100%;
      max-width: 100%;
    }
    @media (max-width: 756px) {
      display: flex;
      flex-direction: column;
    }
  }
  .single-tile {
    margin-top: 24px;
    // margin-bottom: 27px;
    @media (max-width: 756px) {
      min-height: 400px;
      overflow: hidden;
    }
  }
  &__slider {
    border-radius: 8px;
    padding: 47px 59px 97px;
    height: max-content;
    img {
      border-radius: 8px;
    }
    @media (min-width: 756px) {
      // width: 687px;
      border: 1px solid #eeeeee;
    }
    @media (max-width: 756px) {
      padding: 47px 9px 97px;
    }
    &--small {
      padding: 47px 59px 17px;
      @media (max-width: 756px) {
        padding: 47px 0px 17px;
      }
    }
  }
  &__descript {
    border: 1px solid #eeeeee;
    border-radius: 8px;
    padding: 24px 56px;
    margin-top: 24px;
    @media (max-width: 756px) {
      padding: 24px 0px;
      margin-top: 0;
    }
    .skeleton {
      &__column span {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
        margin-right: 10px;
      }
      &__wrapper {
        display: flex;
        margin-top: 23px;
      }
    }
  }
  .single-tile {
    .btn--arrow-right {
      position: absolute;
    }
  }
}

.product-preview {
  .home-page-preloader {
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
    display: flex;
    align-items: center;
  }
}
