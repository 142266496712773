.progress-indicator {
  display: flex;
  align-items: center;
  &__wrapper {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 80px;
    width: 100%;
  }
  .point {
    align-items: center;
    background-color: #c4c4c4;
    display: flex;
    border-radius: 20px;
    color: #ffffff;
    font-size: 14px;
    font-weight: 700;
    justify-content: center;
    height: 26px;
    width: 26px;
    z-index: 1;
    &--active {
      background-color: #ffa914;
    }
    &__wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
    }
    &__label {
      color: #c4c4c4;
      font-weight: 500;
      letter-spacing: 0px;
      font-size: 10px;
      margin-top: 5px;
      line-height: 150%;
      position: absolute;
      white-space: nowrap;
      top: 25px;
      &--active {
        color: #ffa914;
      }
    }
  }
  .line {
    background-color: rgba(196, 196, 196, 1);
    height: 2px;
    width: 174px;
    @media (max-width: 756px) {
      width: 100px;
    }
    &--active {
      background-color: #ffa914;
    }
  }
}
