.invoices {
  margin: auto;
  width: 100%;
  height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ::-webkit-scrollbar {
    width: 10px;
    border-radius: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #ececec;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgb(185, 185, 185);
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .list {
    margin-top: 50px;
    width: calc(100% - 100px);
    height: calc(100% - 100px);
    overflow-y: scroll;
    overflow-x: hidden;

    .title {
      width: 200px;
      font-size: 20px;
      padding-bottom: 20px;
    }

    .listItem {
      padding: 5px 20px;
      width: calc(100% - 20px);
      height: 50px;
      background-color: rgb(235, 235, 235);
      margin: 15px 5px;
      border-radius: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      opacity: 0.75;

      &__right {
        .button-open {
          text-align: center;
          padding-top: 20px;
          margin: 0;
          display: flex;
          align-items: center;
          font-size: 15px;
          color: rgb(119, 119, 119);
        }
      }
    }
  }
}
