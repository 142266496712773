.seller {
  padding: 20px;
  margin-top: 24px;
  border-bottom: 1px solid #eaeaea;
  border: 1px solid #eeeeee;
  border-radius: 8px;
  &__pin {
    margin-right: 5px;
    height: 11px;
    width: 11px;
    path {
      fill: #000000;
    }
  }
  &__delivery {
    display: flex;
    justify-content: space-between;
  }
  &__location {
    margin-top: 40px;
    margin-bottom: 40px;
    position: relative;
    &--skeleton {
      height: 50px;
      span:first-child {
        margin-bottom: 10px;
      }
    }
    &::after {
      content: '';
      width: 334px;
      position: absolute;
      bottom: -20px;
      border-top: 1px solid #eaeaea;
      left: -20px;
      @media (max-width: 756px) {
        width: calc(100% + 40px);
      }
    }
    &::before {
      content: '';
      width: 334px;
      position: absolute;
      top: -20px;
      border-top: 1px solid #eaeaea;
      left: -20px;
      @media (max-width: 756px) {
        width: calc(100% + 40px);
      }
    }
    &__header {
      font-weight: 700;
      font-size: 12px;
      color: #2a2a2a;
      text-transform: uppercase;
      margin-bottom: 7px;
    }
    &__label {
      font-weight: 400;
      font-size: 10px;
      display: flex;
      align-items: center;
    }
  }
  &--top {
    display: flex;
    // justify-content: space-between;
    align-items: flex-start;
    gap: 28px;
    font-weight: 500;
    font-size: 12px;
    &--skeleton {
      height: 50px;
    }
  }
  &__label {
    &--name {
      font-weight: 700;
      font-size: 12px;
      color: #212121;
      margin-bottom: 8px;
    }
  }
  &__rating {
    display: flex;
    align-items: center;
    &--star {
      font-weight: 700;
      line-height: 18px;
      font-size: 12px;
      display: flex;
      align-items: center;
    }
    &--number {
      color: #212121;
      font-weight: 400;
      font-size: 12px;
      margin-left: 5px;
    }
  }
  &--comments {
    font-size: 10px;
    color: #ddd;
    font-weight: 400;
    text-decoration: underline;
    text-align: right;
    cursor: pointer;
  }
  &--send-message {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 1px;
    font-weight: 500;
    display: flex;
    margin-top: 20px;
    justify-content: flex-end;
    cursor: pointer;
  }
  &--chevrone {
    transform: rotate(270deg);
  }
}
