.main-footer {
  color: #fff;
  display: flex;
  justify-content: space-between;
  padding-top: 58px;
  position: relative;
  padding: 58px 0;
  max-width: 1038px;
  width: calc(100% - 42px);
  @media (max-width: 756px) {
    flex-direction: column;
  }
  &__wrapper {
    background-color: #272727;
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 9;
  }
  &__half {
    width: 50%;
    min-width: 515px;
    margin-bottom: 50px;
    display: flex;
    justify-content: space-between;
    @media (max-width: 600px) {
      flex-direction: column;
      margin-bottom: 20px;
    }
  }
  &__column {
    // width: 50%;
    max-width: 300px;
    @media (max-width: 734px) {
      width: 250px;
      margin: 20px auto;
    }
    @media (max-width: 200px) {
      &--icons {
        margin: 20px auto !important;
      }
    }
    @media (max-width: 734px) {
      &--icons {
        margin: 40px auto !important;
      }
    }
  }
  &--cookie {
    position: absolute;
    right: 0;
    top: 165px;
    cursor: pointer;
    @media (max-width: 756px) {
      position: relative;
      margin: 20px auto 0 auto;
      top: auto;
    }
  }
  &--header {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 25px;
  }
  &--name {
    font-size: 36px;
    font-weight: 500;
    margin-bottom: 25px;
    margin-top: -15px;
    @media (max-width: 756px) {
      // margin-left: -40px;
      svg {
        max-width: 350px;
      }
    }
  }
  &--links {
    display: flex;
    flex-wrap: wrap;
    margin-top: 44px;
    @media (max-width: 600px) {
      margin-bottom: 25px;
      margin-top: 0;
    }
  }
  &__link {
    width: 100%;
    text-decoration: none;
    text-transform: capitalize;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    margin: 16px 0;
    &--yellow {
      color: #ffc700;
    }
  }
  &__data {
    margin: 20px 0;
    font-size: 16px;
    color: #fff;
    font-weight: 500;
    line-height: 27px;
    display: flex;
    &--icon {
      margin-right: 20px;
      width: 20px;
      height: 20px;
      margin-top: 5px;
    }
    &--phone {
      color: #fff;
      text-decoration: none;
    }
  }
  &__social-icon {
    &:nth-child(n + 2) {
      margin-left: 29px;
    }
  }
  &__social-icons {
    display: flex;
    justify-content: center;
    @media (max-width: 1085px) {
      justify-content: flex-start;
    }

    @media (max-width: 600px) {
      margin-bottom: 20px;
    }
  }
}
