.product-delivery {
  padding: 20px;
  border-bottom: 1px solid #eaeaea;

  &--name {
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    text-transform: uppercase;
    margin-bottom: 10px;
  }
  &--adress {
    font-weight: 400;
    line-height: 15px;
    font-size: 10px;
    svg {
      height: 10px;
    }
  }
  &__shipment {
    padding: 20px 40px 0 20px;
    display: flex;
    justify-content: space-between;
    text-transform: uppercase;
    font-weight: 700;

    font-size: 12px;
  }
}
