.notify {
  display: flex;
  height: 100%;
  align-items: center;
  &__icon {
    width: 40px;
    height: 100%;
    margin-right: 20px;
    margin-left: 5px;
    flex-shrink: 0;
  }
  &__label {
    font-size: 14px;
    line-height: 21px;
  }
}
