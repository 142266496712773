.alert {
  height: 100%;
  &__title {
    font-size: 14px;
    font-family: $font__poppins;
    font-weight: $weight__medium;
    color: rgba(255, 255, 255, 0.35);
    padding-bottom: 15px;
  }
  &__content {
    display: flex;
    justify-content: space-between;
  }
  &__left {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    font-weight: $weight__regular;
    color: rgba(255, 255, 255, 0.97);
    .badge {
      margin-right: 21px;
    }
  }
}

.Toastify {
  &__toast-container {
    width: 100% !important;
    padding: 4px 4px 0 4px !important;
    @include md {
      width: 365px !important;
    }
  }
  &__toast-container--bottom-right {
    bottom: 82px !important;
    @include md {
      right: 20px !important;
      bottom: 20px !important;
    }
  }
  &__toast {
    min-height: 101px !important;
    min-width: 100% !important;
    border-radius: unset !important;
    border-radius: 8px !important;
    padding: 0 !important;
    @include md {
      right: 4px !important;
      min-width: 365px !important;
    }
    &:last-child {
      margin-bottom: 0 !important;
    }
    &--default {
      // background: #00241c !important;
    }
  }
  &__toast-body {
    padding: 14px 20px !important;
    margin: unset !important;
    width: 100% !important;
  }
  &__close-button {
    position: absolute !important;
    top: 12px !important;
    right: 10px !important;
    svg {
      width: 20px !important;
      height: 20px !important;
    }
    &--default {
      color: rgba(107, 107, 107, 0.45) !important;
      opacity: 1 !important;
    }
  }
}
