.product-badge {
  padding: 15px 0;
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
  margin: 18px 0 20px 0;
  span {
    font-size: 10px;
    line-height: 15px;
    font-weight: 400;
    max-width: 120px;
    margin-right: 30px;
  }
  p {
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 600;
    padding: 0 25px;
    &:before {
      content: '';
      display: block;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background-color: green;
      margin-right: 10px;
    }
  }
  &--gray {
    background-color: #f5f5f5;
  }
  &--orange {
    background-color: #fff8f1;
  }
  &--green {
    background-color: rgba(#15C51C, .12);
    p{
      color: #15C51C;
    }
  }
}
