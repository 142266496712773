.cookies {
  position: absolute;
  bottom: 45px;
  right: 0px;
  z-index: 9999;
  background-color: #fff;
  padding: 34px 43px;
  border: 1px solid #dddddd;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
  max-width: 395px;
  border-radius: 8px;
  cursor: default;
  @media (max-width: 1200px) {
    right: 40px;
  }
  @media (max-width: 1130px) {
    right: 80px;
  }
  @media (max-width: 756px) {
    position: fixed;
    top: 20px;
    left: 20px;
    right: 20px;
    bottom: unset;
    z-index: 999;
    max-width: 100%;
  }
  &__container {
    position: relative;
  }
  &__wrapper {
    position: fixed;
    max-width: 1038px;
    width: calc(100% - 42px);
    bottom: 0px;
    z-index: 999;
  }
  &__modal {
    .modal__inner--cookies__modal {
      padding: 50px 49px 20px 49px;
      width: 631px;
      height: 715px;
      @media (max-width: 756px) {
        width: calc(100% - 42px);
        min-width: 0px;
        height: calc(100% - 120px);
        margin: 0 auto;
        padding: 36px 13px 0 13px;
        .modal-actions {
          position: sticky;
          bottom: 0;
          padding: 25px 0;
          background-color: white;
          z-index: 9999;
        }
      }
      p {
        font-size: 12px;
        font-weight: 400;
      }
    }
    &__section {
      min-height: 107px;
      &--top {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 12px;
      }
      &--header {
        font-weight: 600;
        font-size: 12px;
      }
    }
    &--header {
      text-align: center;
      font-weight: 600;
      font-size: 24px;
      margin-bottom: 29px;
    }
    &--top {
      margin-bottom: 47px;
      p {
        min-height: 125px;
        font-size: 10px;
      }
      a {
        font-size: 10px;
        font-weight: 400px;
        color: #ffa914;
        text-decoration: underline;
      }
    }
  }
  &--buttons {
    margin-top: 20px;
    .btn:nth-child(2) {
      margin: 18px 0;
    }
    .btn {
      width: 100%;
    }
  }
  &--paragraph {
    font-size: 12px;
    font-weight: 400;
    line-height: 17.72px;
    color: black;
    &--yellow {
      color: #ffa914;
      font-weight: 600;
      cursor: pointer;
    }
  }
}
