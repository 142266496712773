.breadcrumbs {
  display: flex;
  align-items: center;
  text-transform: capitalize;
  &--icon {
    transform: rotate(90deg);
  }
  &__item {
    margin: 0 2px;
    color: #c6c6c6;
    font-size: 10px;
    font-weight: 500;
    padding-top: 5px;
    cursor: pointer;

    &--first {
      color: #3d3d3d;
      font-weight: 700;
      margin-right: 22px;
      display: flex;
      align-items: center;
    }
    &--last {
      color: #3d3d3d;
    }
  }
}
