.user-management__table__content {
  &--country {
    text-transform: uppercase;
    @media (max-width: 756px) {
      margin-left: auto;
    }
  }

  &--desktop {
    // font-size: 3px;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    word-wrap: no-wrap;
    max-width: 140px;
    white-space: nowrap;
    line-height: 18px;
    @media (max-width: 756px) {
      display: none;
    }
  }
  &--lang {
    margin-left: 30px;
  }
  &--marginAuto {
    margin-left: auto !important;
  }
  .rt-tr {
    display: flex;
    align-items: center;
    width: 100%;
    @media (max-width: 1050px) {
      width: unset;
    }
    @media (max-width: 756px) {
      width: 100%;
    }
  }
  .rt-tbody {
    width: 100%;
    min-width: 100% !important;
    // overflow: auto;
    @media (max-width: 756px) {
      overflow: hidden;
    }
  }
  .rt-td {
    &:nth-child(5) {
      .badge {
        margin-left: auto;
        margin-right: unset;
      }
    }
    &:last-child {
      margin-left: auto;
      @media (max-width: 756px) {
        margin-left: unset;
      }
    }
    &:nth-child(3) {
      @media (max-width: 756px) {
        margin-left: 6px;
      }
    }
    &:nth-child(6) {
      @media (max-width: 756px) {
        margin-left: auto;
        margin-right: -15px;
      }
    }
  }

  &--state {
    text-align: right;
    font-size: 12px;
    &--SUSPENDED {
      color: #929292;
    }
    &--UNACTIVE {
      color: #fc4e4e;
    }
  }

  .rt-tr-group {
    margin: 10px 0;
    width: 100%;
    font-size: 14px;
    display: flex;
    align-items: center;
    .rt-tr {
      background: #eeeeee;
      border-radius: 16px;
      display: flex;
      padding: 13px 20px 13px 0;
      // min-width: 100%;
      @media (max-width: 756px) {
        padding: 8px 20px 8px 0;
        font-size: 12px;
      }
    }
    .badge {
      &__wrapper {
        width: calc(100% - 25px);
        margin-left: 25px;
        display: flex;
        @media (max-width: 756px) {
          margin-left: 8px;
        }
      }
      margin-right: auto;
      div {
        margin-right: auto;
      }
    }
    &--name {
      max-width: 176px;
      width: 100%;
    }
    &--surname {
      max-width: 190px;
      width: 100%;
    }
    &--email {
      max-width: 263px;
      width: 100%;
    }
    &--settings {
      max-width: 63px;
      width: 100%;
      margin-left: auto;
      margin-right: 22px;
      display: flex;
      justify-content: space-between;
      div {
        cursor: pointer;
      }
    }
    // &--user {
    //     margin-right: 61px;
    // }
  }
}

.countries {
  .rt-td {
    &:nth-child(1) {
      font-weight: 600;
      margin-left: 20px;
    }
  }
}
