.search-by-vehicle {
  padding: 24px 16px;
  background-color: #f5f5f5;
  border-radius: 16px;
  @media (max-width: 756px) {
    padding: 0;
  }
  .regular-field__label-error {
    position: absolute;
  }
  &--heading {
    margin-left: 12px;
  }
  &--name {
    color: #262626;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 18px;
  }
  .select-field__label,
  .regular-field__label {
    color: #272727;
  }
  .regular-field--half {
    width: 140px;
    @media (max-width: 756px) {
      width: 100%;
    }
  }
  .regular-field::placeholder {
    text-decoration: none !important;
  }
  input {
    &::placeholder {
      text-decoration: none;
      color: #eee;
    }
  }
  .select-field__label {
    color: black !important;
  }
  .search-parts__form--half {
    .regular-field {
      width: calc(50% - 5px);
      @media (max-width: 756px) {
        width: calc(50% - 10px);
      }
    }
  }
  .regular-field__icon--wrapper {
    width: calc(50% - 27px);
    @media (max-width: 756px) {
      width: 100%;
    }
    .regular-field {
      width: 100%;
    }
  }
  &--buttons {
    @media (max-width: 756px) {
      display: flex;
      justify-content: space-around;
      width: 100%;
      .btn {
        max-width: 140px;
        width: 100%;
      }
    }
  }
  .react-select__menu {
    z-index: 99999999;
    max-height: 90px;
    overflow: hidden;
  }
  &--modal {
    display: flex;
    &--left {
      display: flex;
      flex-direction: column;
      margin-right: 10px;
    }
    &--heading {
      color: #ffa914;
      font-size: 24px;
      line-height: 36px;
      font-weight: 700;
      max-width: 390px;
    }
    &--descript {
      max-width: 300px;
      color: #272727;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      margin-top: 10px;
    }
  }
}

.search-parts__form--half {
  .regular-field {
    width: calc(50% - 5px);
  }
}
.product-listcontent {
  .search-by-vehicle {
    margin-bottom: 10px;
  }
}
