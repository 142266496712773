.single-category-bar {
  background-color: #f5f5f5;
  display: flex;
  min-width: 330px;
  width: calc(100% - 22px);
  padding: 17px 50px 18px 0px;
  align-items: center;
  border-radius: 4px;
  flex-grow: 1;
  cursor: pointer;
  &--name {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    pointer-events: none;
    &--all {
      color: #ffa914;
    }
  }
  &--quantity {
    color: #bdbdbd;
    font-weight: 400;
    font-size: 12px;
    margin-left: auto;
  }
  &--chevrone {
    transform: rotate(270deg);
    margin-left: 20px;
    margin-right: 11px;
    &--down {
      transform: rotate(0deg);
      transition: 0.3s;
    }
    &--top {
      transform: rotate(180deg);
      transition: 0.3s;
    }
  }
  &__list {
    position: relative;
    padding-left: 24px;
    &:after {
      content: '';
      width: 16px;
      height: 16px;
      border-radius: 3px;
      position: absolute;
      border: 1px solid #dddddd;
      right: 20px;
      transition: 0.3s;
    }
    &--active {
      &:after {
        background-color: #ffa914;
        border: 1px solid #ffa914;
      }
      &:before {
        content: '';
        top: 21px;
        right: 25px;
        width: 4px;
        height: 7px;
        border: solid white;
        border-width: 0 3px 3px 0;
        transform: rotate(45deg);
        position: absolute;
        border-radius: 1px;
        z-index: 9;
      }
    }
    &--active-gray {
      &:after {
        background-color: #aaaaaa;
        border: 1px solid #aaaaaa;
      }
      &:before {
        content: '';
        top: 21px;
        right: 25px;
        width: 4px;
        height: 7px;
        border: solid white;
        border-width: 0 3px 3px 0;
        transform: rotate(45deg);
        position: absolute;
        border-radius: 1px;
        z-index: 9;
      }
    }
  }
}
