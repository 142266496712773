.allegro-successful-connection {
  &__wrapper {
    min-height: calc(80vh - 50px);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  &__content {
    gap: 50px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    .btn {
      width: 100%;
    }
    &--text {
      display: flex;
      align-items: center;
      font-size: 18px;
      @media (max-width: 400px) {
        display: block;
      }
      svg {
        margin-left: 4px;
        height: 22px;
        width: auto;
      }
      .svg--circle-red-close {
        margin-left: 10px;
      }
    }
    span {
      max-width: 338px;
      font-size: 14px;
      text-align: center;
      line-height: 147%;
    }
    .photo .btn {
      margin-top: 60px;
    }
  }
}
