.checkbox {
  display: flex;
  align-items: flex-end;
  margin: 15px 0;
  &__label {
    display: block;
    width: 60%;
    position: relative;
    padding-left: 40px;
    font-size: 14px;
    letter-spacing: 0.022em;
    margin-top: 10px;
    cursor: pointer;
    @media (max-width: 756px) {
      padding-right: 8px;
    }
    &--active {
      &:after {
        background-color: #ffa914;
        border: 1px solid #ffa914;
      }
      &:before {
        content: '';
        top: 1px;
        left: 11px;
        width: 4px;
        height: 7px;
        border: solid white;
        border-width: 0 3px 3px 0;
        transform: rotate(45deg);
        position: absolute;
        border-radius: 1px;
        z-index: 9;
      }
    }
    &:after {
      content: '';
      width: 16px;
      height: 16px;
      border-radius: 3px;
      position: absolute;
      border: 1px solid #dddddd;
      transition: 0.3s;
      left: 5px;
      top: -2px;
    }
  }
}
