.languageSwitch {
  input {
    color: transparent !important;
    text-shadow: 0 0 0 #000 !important;
    &:focus {
      outline: none !important;
    }
  }
  .select-field {
    margin: 0;
    &__label {
      text-transform: uppercase;
      font-size: 18px;
      color: #000000;
      font-weight: 400;
      @media (max-width: 756px) {
        color: #f5f5f5;
        &:after {
          background-color: transparent;
        }
      }
    }
    &__wrapper {
      @media (max-width: 756px) {
        margin-left: 48px;
        margin-bottom: -40px;
        margin-top: 50px;
      }
    }
  }
  .css-tlfecz-indicatorContainer {
    padding-left: 0 !important;
  }
  .react-select {
    &__single-value {
      display: none;
    }
    &__control {
      position: relative;
      width: 56px;
      &--is-focused {
        box-shadow: unset !important;
      }
      @media (max-width: 756px) {
        background-color: #272727 !important;
      }
    }
    &__indicators {
      position: absolute;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      z-index: 9;
      cursor: pointer;
      @media (max-width: 756px) {
        background-color: transparent;
      }
      svg {
        margin-top: 2px;
        path {
          fill: #929292;
        }
      }
    }
    &__dropdown-indicator {
      padding-left: 2px !important;
    }
    &__menu {
      width: 56px;
      &-list {
        margin: 0;
        padding: 0;
      }
    }
    &__option {
      display: flex;
      justify-content: center;
      font-size: 18px;
      color: #000;
      cursor: pointer;
      background-color: transparent;
      &:nth-child(1) {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }
      &:nth-child(2) {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
      }
      &:hover,
      &:focus,
      &:active {
        background-color: #dddddd;
        // background-color: red;
      }
    }
  }
}
