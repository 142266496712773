.advertisement-tile {
  display: flex;
  flex-direction: column;
  padding: 30px 25px;
  background-color: #ffa914;
  border-radius: 16px;
  position: relative;
  margin: 4.5px 0;
  &--heading {
    font-weight: 700;
    font-size: 24px;
    line-height: 35px;
    color: #fff;
  }
  &--paragraph {
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    color: #fff;
  }
  &--actions {
    display: flex;
    margin-top: 25px;
  }
  &--image {
    width: 250px;
    justify-self: flex-end;
    position: absolute;
    right: 0;
    bottom: 0;
    border-radius: 16px;
  }
}
