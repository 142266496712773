.single-brand-bar {
  display: flex;
  min-width: 150px;
  padding: 17px 50px 18px 0px;
  align-items: center;
  border-radius: 4px;
  flex-grow: 1;
  justify-content: flex-start;
  cursor: pointer;
  position: relative;
  &--first{
    position: absolute;
    left: -40px;
    border: 1px solid #CBCBCB;
    border-radius: 4px;
    width: 28px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
  }
  &--name {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    pointer-events: none;
    color:#929292;
    &--all {
      color: #ffa914;
    }
  }
  &--quantity {
    color: #bdbdbd;
    font-weight: 400;
    font-size: 12px;
    margin-left: 5px;
  }
  &__list {
    position: relative;
    padding-left: 24px;
    &:after {
      content: '';
      width: 16px;
      height: 16px;
      border-radius: 3px;
      position: absolute;
      border: 1px solid #dddddd;
      right: 20px;
      transition: 0.3s;
    }
    &--active {
      &:after {
        background-color: #ffa914;
        border: 1px solid #ffa914;
      }
      &:before {
        content: '';
        top: 21px;
        right: 25px;
        width: 4px;
        height: 7px;
        border: solid white;
        border-width: 0 3px 3px 0;
        transform: rotate(45deg);
        position: absolute;
        border-radius: 1px;
        z-index: 9;
      }
    }
    &--active-gray {
      &:after {
        background-color: #aaaaaa;
        border: 1px solid #aaaaaa;
      }
      &:before {
        content: '';
        top: 21px;
        right: 25px;
        width: 4px;
        height: 7px;
        border: solid white;
        border-width: 0 3px 3px 0;
        transform: rotate(45deg);
        position: absolute;
        border-radius: 1px;
        z-index: 9;
      }
    }
  }
}
