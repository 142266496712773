.basket {
  display: flex;
  align-items: center;
  font-size: 12px;
  flex-shrink: 0;
  position: relative;
  &--disable {
    pointer-events: none;
  }
  &__wrapper {
    position: relative;
    &--mobile {
      position: unset;
    }
  }
  cursor: pointer;
  &__count {
    &--wrapper {
      position: absolute;
      right: -9px;
      top: -9px;
      z-index: 9999;
    }
    min-width: 16px;
    min-height: 16px;
    border-radius: 10px;
    background-color: #ffa914;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    font-weight: 600;
  }
  &--unpaid {
    &:after {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      color: #fff;
      content: '!';
      position: absolute;
      width: 16px;
      height: 16px;
      background-color: #fc4e4e;
      right: -9px;
      top: -9px;
      border-radius: 10px;
    }
  }
  &--img {
    margin-left: 8px;
    &--active path {
      fill: rgba(255, 169, 20, 1);
    }
    &--unpaid {
      position: relative;
      path {
        fill: #fc4e4e;
      }
    }
  }
}
.summary-btn--gray {
  background-color: #c4c4c4 !important;
  pointer-events: none;
}
.basket-modal {
  position: absolute;
  top: 25px;
  right: 0;
  z-index: 9;
  background-color: #fff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
  border: 1px solid #dddddd;
  padding: 25px 21px;
  min-width: 332px;
  border-radius: 8px;
  .next {
    width: 100%;
    flex-basis: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 15px;
    border-bottom: 1px solid #dddddd;
    cursor: pointer;
  }
  .before {
    cursor: pointer;
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 15px;
    transform: rotate(180deg);
  }
  &--empty {
    text-align: center;
  }
  &--mobile {
    top: 45px;
    box-shadow: unset;
    border: unset;
    width: 100vw;
    height: 100vh;
    right: -39px;
  }
  &__item {
    display: flex;
    padding-bottom: 26px;
    border-bottom: 1px solid #dddddd;
    justify-content: space-between;
    &--wrapper {
      &:nth-child(n + 2) {
        padding-top: 26px;
      }
    }
    &--last {
      border: unset;
    }
    &--left {
      display: flex;
    }
    &--info {
      display: flex;
      flex-direction: column;
    }
    &--image {
      max-width: 61px;
      border-radius: 4px;
      margin-right: 14px;
      object-fit: cover;
      max-height: 60px;
    }
    &--name {
      text-transform: uppercase;
      color: #262626;
      font-weight: 600;
      max-width: 107px;
      font-size: 12px;
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
    }
    &--descript {
      color: #b0b0b0;
      font-size: 10px;
      max-width: 107px;
      margin-top: 8px;
      p {
        margin: 3px 0;
      }
    }
    &--right {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: space-between;
      .button-disabled {
        color: #d6d6d6;
        pointer-events: none;
      }
    }
    &--delete {
      font-size: 10px;
      color: #fc4e4e;
      font-weight: 500;
      cursor: pointer;
    }
    &--price {
      color: #262626;
      font-size: 12px;
      font-weight: 600;
      text-transform: uppercase;
    }
  }

  &__summary {
    margin-top: 20px;
    &--descript {
      margin: 7px 0;
      display: flex;
      justify-content: space-between;
      &--delivery {
        font-size: 10px;
        font-weight: 500;
        line-height: 150%;
      }
      &--payment {
        font-size: 12px;
        font-weight: 600;
        line-height: 150%;
      }
      span {
        text-transform: uppercase;
      }
    }
    .btn {
      width: 100%;
      &:nth-child(2n) {
        margin-top: 34px;
      }
      margin-top: 15px;
      p {
        font-weight: 00;
      }
    }
  }
}

.unpaid-order {
  display: flex;
  flex-direction: column;
  .heading {
    color: #fc4e4e;
    font-size: 14px;
    font-weight: 500;
    line-height: 147%;
    text-align: center;
    margin-bottom: 25px;
  }
  .descript {
    width: 100%;
    text-align: center;
    color: #c4c4c4;
  }
}
