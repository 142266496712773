.finder {
  border-radius: 16px;
  background-color: #f5f5f5;
  padding: 10px 22px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  &--descript {
    font-size: 14px;
    &--bold {
      font-weight: 700;
      text-decoration: underline;
    }
  }
  &__image {
    margin-right: 20px;
  }
}
