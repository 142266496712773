.product-buttons {
  display: flex;
  justify-content: space-between;
  margin: 28px 0 18px;
  gap: 20px;
  @media (max-width: 756px) {
    flex-direction: column-reverse;
  }
  .btn {
    font-size: 14px;
    width: 133px;
    padding: 11px 0;
    font-weight: 600;
    line-height: 147.69%;
    @media (max-width: 756px) {
      width: 100%;
    }
    &:first-of-type{
      border-radius: 4px;
      border: 2px solid #ffa914;
    }
  }
}
