.about-us {
  min-height: calc(100vh - 355px);
  &__header {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    left: 0;
    top: 45px;
    position: absolute;
    width: 100vw;
    max-height: 500px;
    height: 100%;
    &--ilustration {
      width: 100%;
      max-height: 500px;
      height: 100%;
      object-fit: cover;
    }
    &--content {
      display: flex;
      max-width: 1038px;
      width: 1038px;
      margin-top: -250px;
      @media (max-width: 1104px) {
        padding: 0 40px;
      }
      @media (max-width: 850px) {
        flex-wrap: wrap;
        padding: 0 20px;
        margin-top: -300px;
      }
    }
    &--left {
      display: flex;
      flex-direction: column;
      border-right: 2px solid #ffffff;
      padding-right: 20px;
      text-transform: capitalize;
      @media (max-width: 850px) {
        border-right: 0;
        border-bottom: 2px solid #ffffff;
        padding-bottom: 14px;
      }
      .content {
        display: flex;
        align-items: flex-end;
      }
      .orange {
        font-size: 64px;
        font-weight: 900;
        color: #ffa914;
        @media (max-width: 850px) {
          font-size: 48px;
        }
      }
      .year {
        font-size: 64px;
        font-weight: 900;
        color: #fff;
        margin-left: 20px;
        text-transform: uppercase;
        @media (max-width: 850px) {
          font-size: 48px;
        }
      }
      .market {
        font-size: 48px;
        font-weight: 900;
        color: #fff;
        letter-spacing: 0.205em;
        margin-top: 20px;
        text-transform: uppercase;
        @media (max-width: 850px) {
          font-size: 36px;
          margin-top: 10px;
        }
      }
    }
    &--right {
      max-width: 413px;
      font-size: 14px;
      font-weight: 500;
      text-transform: capitalize;
      padding-left: 20px;
      letter-spacing: 0.205em;
      color: #fff;
      line-height: 147.69%;
      @media (max-width: 730px) {
        padding-left: 0px;
        padding-top: 20px;
      }
    }
  }
  &--content {
    display: flex;
    margin-top: 566px;
    width: 100%;
    background-color: #fff8f1;
    border-radius: 16px;
    padding: 27px 50px;
    @media (max-width: 850px) {
      margin-top: 470px;
      flex-wrap: wrap;
      padding: 27px 20px;
    }
    .ilustration {
      width: 100%;
      border-radius: 10px;
    }
    .header {
      font-size: 20px;
      line-height: 147%;
      color: #272727;
      margin-bottom: 21px;
    }
    .right {
      min-width: 300px;
      @media (max-width: 850px) {
        width: 100%;
        border-radius: 16px;
        min-width: 100px;
      }
    }
    .left {
      padding-right: 30px;
      @media (max-width: 850px) {
        padding-right: 0;
        padding-bottom: 30px;
      }
      .content {
        line-height: 160%;
        font-weight: 400;
        font-size: 14px;
        color: #272727;
      }
    }
  }

  &__counters {
    display: flex;
    justify-content: space-between;
    padding: 60px 50px;
    @media (max-width: 850px) {
      flex-wrap: wrap;
      gap: 90px;
      justify-content: center;
      padding: 60px 10px;
    }
    &--item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      max-width: 300px;
      svg {
        margin: 0 auto;
      }
    }
    .counters {
      font-size: 40px;
      line-height: 160%;
      font-weight: 700;
      letter-spacing: 0.205em;
      color: #ffa914;
      text-align: center;
    }
    .paragraph {
      text-align: center;
      font-size: 20px;
      font-weight: 700;
      color: #272727;
    }
  }
  &__map {
    &--container {
      border-radius: 16px;
      height: 363px;
      margin-bottom: 100px;
      position: relative;
      @media (max-width: 850px) {
        display: flex;
        flex-direction: column-reverse;
        height: 100%;
      }
      svg {
        width: 100%;
        height: 100%;
        margin-left: -100px;
        @media (max-width: 850px) {
          margin-left: 0;
          background: #ffffff;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          border-radius: 16px;
          // height: 300px;
        }
      }
    }
    &--rectangle {
      background-color: #1d1d1b;
      border-radius: 16px;
      position: absolute;
      display: flex;
      top: 0;
      right: 0;
      height: 100%;
      padding: 40px;
      max-width: 340px;
      text-align: center;
      align-items: center;
      @media (max-width: 850px) {
        position: relative;
        background-color: transparent;
        color: #272727;
        max-width: 100%;
      }
      u {
        text-align: center;
        font-size: 28px;
        line-height: 147.69%;
        text-decoration: none;
        margin: 0 5px;
        color: rgba(255, 169, 20, 1);
      }
      .paragraph {
        font-size: 24px;
        color: #fff;
        max-width: 343px;
        line-height: 147.69%;
        b {
          color: #ffa914;
        }
        @media (max-width: 850px) {
          color: #272727;
        }
      }
    }
  }
}
