.input-counter {
  display: flex;
  align-items: center;
  .input {
    max-width: 30px;
    display: flex;
    text-align: center;
    border: 1px solid #eeeeee;
    pointer-events: none;
    height: 100%;
    &:focus {
      outline: none;
    }
  }
  .button {
    padding: 0 5px;
    border: 1px solid #eeeeee;
    cursor: pointer;
    height: 100%;
    display: flex;
    align-items: center;
  }
}
