.add-advertisement {
  width: 100%;
  min-height: calc(100vh - 50px);
  padding-top: 66px;
  .regular-field__label {
    text-transform: uppercase;
  }
  &--heading {
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    margin-bottom: 35px;
  }
  &__half-input {
    width: 50%;
    @media (max-width: 756px) {
      width: 100%;
    }
  }
  section {
    border-radius: 16px;
    background-color: #f5f5f5;
    padding: 42px 177px;
    margin-bottom: 20px;
    @media (max-width: 1024px) {
      padding: 42px 140px;
    }
    @media (max-width: 900px) {
      padding: 42px 60px;
    }
    @media (max-width: 756px) {
      padding: 25px 17px;
    }
    .heading {
      font-size: 18px;
      font-weight: 400;
      margin-bottom: 11px;
      @media (max-width: 756px) {
        text-align: center;
      }
    }
    .descript {
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0.2px;
      color: #929292;
    }
  }
  &__field {
    // input {
    //   margin-bottom: 10px;
    // }
    label {
      color: #272727;
    }
    &--textarea,
    &--textarea-translate {
      textarea {
        width: calc(100% - 17px);
        height: 230px;
        border: 0;
        border-radius: 4px;
        resize: none;
        padding: 15px 10px;
      }
      .regular-field__label-error {
        margin-top: 20px;
      }
      label {
        position: absolute;
        top: -5px;
        text-transform: uppercase;
      }
      &--error {
        color: #fc4e4e;
      }
    }
  }
  &__product-descript {
    &--bottom {
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      line-height: 21px;
      color: #929292;
      padding-right: 17px;
      margin-top: 10px;
      &--end {
        justify-content: flex-end;
      }
    }
    .btn {
      margin: 30px 0 40px 0;
    }
  }

  &__select {
    .react-select__option {
      text-transform: capitalize !important;
    }
    .select-field {
      margin: 28px 0;
      &:last-child {
        margin-bottom: 10px;
      }
      &__label {
        top: -5px;
        color: #272727;
        text-transform: uppercase;
        &:after {
          height: 6px;
        }
      }
    }
  }

  &--line {
    height: 0.5px;
    background-color: #bdbdbd;
    width: calc(100% + 200px);
    margin-left: -100px;
    margin-bottom: 35px;
    @media (max-width: 900px) {
      width: 100%;
      margin-left: unset;
    }
  }
  &__price {
    width: 50%;
    @media (max-width: 756px) {
      width: 100%;
    }
    label {
      color: #000;
    }
    &--currency {
      position: relative;
      p {
        position: absolute;
        right: 20px;
        top: 29px;
        font-size: 14px;
      }
    }
    input::placeholder {
      text-decoration: unset;
      color: #bdbdbd;
    }
  }
  &__buttons {
    margin-top: 40px;
    margin-bottom: 50px;
    display: flex;
    justify-content: flex-end;
    @media (max-width: 756px) {
      justify-content: center;
    }
    &--preview {
      font-size: 14px;
      font-weight: 500;
      border-bottom: 1px solid black;
      padding-bottom: 5px;
      max-width: 144px;
      text-align: right;
      margin-left: auto;
      cursor: pointer;
      @media (max-width: 756px) {
        margin: 0 auto;
      }
    }
    &--right {
      @media (max-width: 756px) {
        width: 100%;
      }
    }
    &--actions {
      display: flex;
      margin-top: 40px;
      @media (max-width: 756px) {
        flex-direction: column;
        width: 100%;
      }
      .btn:first-child {
        margin-right: 20px;
        @media (max-width: 756px) {
          margin-right: 0;
          margin-bottom: 20px;
          width: 100%;
        }
      }
    }
  }
  &__title-input {
    position: relative;
    .btn {
      position: absolute;
      right: -140px;
      top: 18px;
      @media (max-width: 900px) {
        position: relative;
        top: unset;
        right: unset;
      }
      @media (max-width: 756px) {
        width: 100%;
        position: relative;
        top: unset;
        right: unset;
      }
    }
  }
  .home-page-preloader {
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
    display: flex;
    align-items: center;
  }
}
