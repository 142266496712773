.regular-field {
  position: relative;
  font-family: 'Poppins' !important;
  ::placeholder {
    color: #272727;
    opacity: 1;
  }
  &__input {
    display: block;
    .react-select__indicators,
    .react-select__value-container {
      margin-top: -10px;
    }
    .select-field__label {
      color: #000;
      text-transform: uppercase;
    }
  }
  &__phone-wrap {
    display: flex;
    .select-field__label {
      color: #000;
      text-transform: uppercase;
    }
    .select-field__input--error {
      .react-select__control {
        height: 41px;
      }
    }
    .select-field__error-label {
      font-size: 12px;
      margin-top: 7px;
      font-weight: 300;
      margin-left: 0;
    }
    .select-field__wrapper {
      max-width: 130px;
      margin-right: 5px;
    }
    .regular-field {
      &:nth-child(2) {
        margin-left: auto;
      }
    }
  }
  &__input {
    .eye {
      position: absolute;
      right: 15px;
      top: 25px;
      cursor: pointer;
      &--close {
        top: 27px;
        right: 16px;
      }
    }
    position: relative;
    textarea:focus,
    input:focus {
      outline: none;
    }
    input {
      border: none;
      background-color: #fff;
      margin: 16px 0px;
      padding: 11px 22px;
      border-radius: 4px;
      font-family: $font__poppins;
      width: 100%;
      font-size: 14px;
      font-weight: 400;
      color: #272727;
      &:focus,
      &:focus-visible,
      &:active {
        border: 1px solid rgba(255, 169, 20, 0.5);
        padding: 10px 22px;
      }
    }
    &--error {
      input,
      textarea,
      .react-select__control {
        border: 1px solid #e20d0d !important;
      }
    }
  }
  &__padding {
    &--right input {
      padding-right: 30px;
    }
    &--left {
      input,
      input:focus {
        padding-left: 50px;
      }
    }
  }
  &__label {
    text-transform: uppercase;
    position: absolute;
    top: 11px;
    left: 6px;
    font-size: 10px;
    font-weight: 500;
    color: #ffa914;
    background: linear-gradient(transparent 49%, white 50%);
    padding: 0 2px;
    &-error {
      color: #fc4e4e !important;
      font-size: 12px;
      margin-top: -10px;
      margin-bottom: 10px;
    }
  }
  &__icon {
    &--wrapper {
      position: relative;
      display: flex;
    }
    &--right {
      position: absolute;
      right: 12px;
      top: 26px;
    }
    &--left {
      position: absolute;
      left: 22px;
      top: 26px;
    }
  }
}
