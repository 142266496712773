.svg {
  &--loupe {
    .d {
      fill: black;
    }
    &--orange {
      .d {
        fill: #ffa914;
      }
    }
  }
  &--chevrone {
    &__white {
      path {
        fill: white;
      }
      &--orange {
        path {
          fill: #ffa914;
        }
      }
      &--left {
        transform: rotate(90deg);
      }
      &--right {
        transform: rotate(270deg);
      }
    }
  }
  &--pin--black {
    path {
      fill: #000;
    }
  }
  &--mail--gray path {
    fill: #c4c4c4;
  }
  &--close {
    &-orange {
      width: 10px;
      position: absolute;
      top: 27px;
      cursor: pointer;
      right: 10px;
      path,
      line {
        stroke: orange;
      }
    }
  }
}
