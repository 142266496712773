.partner-badge {
  padding: 2px 7px;
  background-color: #f5f5f5;
  width: 103px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  &--label {
    font-size: 10px;
    color: #9e9e9e;
    font-weight: 600;
    line-height: 15px;
  }
  &--logo {
    width: auto;
    height: 12px;
    margin-left: auto;
    margin-right: 5px;
  }
}
