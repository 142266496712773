.modal-actions {
  margin-top: 36px;
  // display: flex;
  // justify-content: center;
  .btn {
    text-transform: capitalize;
  }
  &__partner {
    display: flex;
    justify-content: center;
    gap: 20px;
    .btn {
      padding: 10px 52px;
    }
  }
  &__assign-client {
    display: flex;
    justify-content: space-evenly;
    .btn {
      padding: 10px 32px;
    }
  }

  &__company-data {
    .btn {
      padding: 10px 50px;
    }
  }
  &__content {
    display: flex;
    justify-content: space-evenly;
    .btn {
      min-width: 140px;
    }
    @media (max-width: 756px) {
      flex-direction: column;
      .btn:first-child {
        margin-bottom: 20px;
      }
    }
    &--add-category {
      max-width: 310px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      @media (max-width: 756px) {
        flex-wrap: wrap;
      }
      .btn {
        padding: 10px 15px;
        max-width: 120px;
        min-width: 120px;
        @media (max-width: 756px) {
          width: 100%;
          max-width: 100%;
        }
      }
    }
  }
}
