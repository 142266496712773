.order-item {
  width: 100%;
  border: 1px solid;
  padding: 30px 17px;
  border-radius: 8px;
  margin: 21px 0;
  &--paid {
    border-color: #87ce8a;
  }
  &--unpaid {
    border-color: #d13239;
  }
  &__header {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
    line-height: 147%;
    margin-bottom: 18px;
  }
}
