.seller-comment {
  padding-left: 20px;
  padding-right: 20px;
  &--skeleton {
    padding-left: 0;
  }
  &--heading {
    color: #19191d;
    font-weight: 600;
    font-size: 16px;
    padding: 21px 0;
    // border-top: 1px solid #eaeaea;
    &--skeleton {
      display: flex;
      flex-direction: column;
      &--first span {
        margin-top: 28px;
        margin-bottom: 12px;
      }
      &--column span {
        margin-top: 3px;
      }
    }
  }
  &--paragraph {
    font-size: 12px;
    color: #212121;
    font-weight: 400;
    line-height: 18px;
    padding-bottom: 20px;
    padding-left: 5px;
    word-break: break-all;
  }
}
