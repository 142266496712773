.searchEngine {
  .search-field {
    margin-top: 40px;
  }
}
.search-field {
  position: relative;
  &--input {
    border: none;
    border-radius: 50px;
    background-color: #fafafa;
    padding: 11px 0 13px 55px;
    width: 100%;
    font-family: $font__poppins;
  }
  &--icon {
    position: absolute;
    left: 25px;
    top: 13px;
  }
  &--advertisements {
    width: 330px;
    margin-right: 8px;
    input {
      border-radius: 4px;
      border: 0.5px solid #dddddd;
      background-color: #fff;
      color: #262626;
      font-family: $font__poppins;
    }
    .svg path {
      fill: #ffa914;
    }
    .search-field {
      margin-top: 0;
    }
  }
}

.search-query-bar {
  width: 100%;
  padding: 16px 55px 16px 22px;
  border-radius: 16px;
  background-color: #f5f5f5;
  position: relative;
  margin-bottom: 20px;
  &--label {
    font-size: 12px;
    font-family: 'Poppins';
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &--close {
    position: absolute;
    top: 13px;
    right: 16px;
    cursor: pointer;
    svg {
      width: 11px;
      height: 11px;
    }
    line,
    path {
      stroke: #929292;
    }
  }
}
