.checkout {
  min-height: calc(100vh - 45px);
  align-content: flex-start;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 1024px) {
    margin-bottom: 100px;
  }
  // .checkout--content--sticky {
  //   .left-side {
  //     width: 100%;
  //   }
  // }
  &--content {
    display: flex;
    width: 100%;
    justify-content: space-between;
    position: relative;
    margin-top: 188px;
    min-height: calc(100vh - 188px);
    @media (max-width: 756px) {
      flex-direction: column;
    }
    .react-select__indicator {
      padding: 4px;
    }
  }
  .sticky {
    padding-top: 66px;
    width: 100%;
    position: fixed;
    max-width: 1038px;
    background-color: #fff;
    z-index: 9;
  }
  .back {
    position: absolute;
    display: flex;
    width: 100%;
    margin-top: -35px;
    @media (max-width: 756px) {
      margin-left: 15px;
    }

    svg {
      transform: rotate(90deg);
    }
    div {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      font-size: 12px;
    }
  }
  .method {
    margin-top: 35px;
    font-size: 14px;
    font-weight: 600;
    line-height: 150%;
  }
  .heading {
    width: 100%;
    text-align: left;
    font-weight: 500;
    margin-bottom: 56px;
    display: flex;
    align-items: center;
    &--capitalize {
      text-transform: capitalize;
    }
    .count {
      width: 19px;
      height: 19px;
      border-radius: 10px;
      background-color: #ffa914;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      font-size: 10px;
      font-weight: 600;
      margin-left: 10px;
    }
  }
  .left {
    z-index: 4;
    align-self: flex-start;
  }
  .left,
  .right {
    @media (min-width: 756px) {
      border: 1px solid #eeeeee;
    }
    border-radius: 16px;
    .heading {
      font-size: 18px;
      font-weight: 600;
      line-height: 148%;
      margin-bottom: 38px;
    }
  }
  .left,
  .left-side {
    @media (min-width: 756px) {
      padding: 0 29px 39px 29px;
    }
    width: calc(70% - 30px);
    @media (max-width: 756px) {
      width: 100%;
    }
    .heading {
      margin-top: 39px;
    }
  }
  .left-side {
    padding: 0;
    z-index: 6;
    .heading {
      margin: 0;
    }
    .content {
      border: 1px solid #eeeeee;
      border-radius: 16px;
      padding: 25px;
      &:nth-child(2) {
        margin-top: 25px;
        @media (max-width: 756px) {
          padding: 15px;
        }
        .heading {
          margin-bottom: 33px;
        }
      }
      &:first-child .heading {
        margin-bottom: 17px;
      }
    }
    .right {
      width: 100% !important;
      margin-left: unset !important;
      margin-top: 25px;
      margin-bottom: 50px;
      svg {
        margin-top: 40px;
      }
      @media (max-width: 756px) {
        margin-bottom: 0px;
      }
    }
  }
  .right {
    width: 30%;
    padding: 25px 29px;
    margin-left: auto;
    @media (max-width: 1024px) {
      margin-right: 40px;
    }
    @media (max-width: 756px) {
      width: 100%;
    }
    &--full {
      width: 100%;
      margin-bottom: 50px;
      margin-top: 25px;
    }
    // &--second {
    //   margin-top: 25px;
    //   @media(max-width: 756px) {
    //     margin-top: 0;
    //   }
    // }
    &__wrapper {
      width: 100%;
      position: fixed;
      max-width: 1038px;
      height: auto;
      z-index: 2;
      @media (max-width: 756px) {
        position: relative;
        height: 100%;
      }
      &--footer {
        position: absolute;
        bottom: 0px;
      }
      &--checkout-footer {
        position: absolute;
        bottom: 50px;
      }
      .buttons {
        display: flex;
        flex-direction: column;
        margin-top: 40px;
        .btn:last-child {
          margin-top: 15px;
        }
        .btn--orange-border {
          margin-top: 25px;
        }
        .descript {
          color: #929292;
          font-size: 10px;
          margin-top: 14px;
          font-weight: 400;
        }
      }
    }
    .text {
      margin-top: 40px;
      font-size: 10px;
      line-height: 15px;
      font-weight: 400;
    }
    .paragraph span {
      text-transform: uppercase;
    }
    .line {
      display: flex;
      justify-content: space-between;
      margin-bottom: 22px;
      font-size: 14px;
      line-height: 150%;
      &--bold {
        font-weight: 600;
      }
    }
    .border-bottom {
      height: 1px;
      width: 100%;
      background-color: #eeeeee;
      margin-bottom: 17px;
    }
    .next {
      width: 100%;
      flex-basis: 100%;
      display: flex;
      justify-content: center;
      padding-bottom: 15px;
      pointer-events: none;
      height: 33px;

      &--on {
        cursor: pointer;
        pointer-events: all;
      }
    }
    .before {
      cursor: pointer;
      width: 100%;
      display: flex;
      justify-content: center;
      padding-top: 15px;
      transform: rotate(180deg);
      height: 33px;
      &--on {
        cursor: pointer;
        pointer-events: all;
      }
    }
    .item__wrapper {
      &:first-child {
        display: none !important;
      }
    }
    .item {
      display: flex;
      margin-bottom: 34px;
      .image {
        width: 69px;
        height: 57px;
        object-fit: contain;
        margin-right: 10px;
        border-radius: 8px;
      }
      div {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-top: 2px;
      }
      .paragraph {
        font-size: 10px;
        font-weight: 600;
        text-transform: uppercase;
        line-height: 150%;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
        max-width: 110px;
        @supports (-webkit-line-clamp: 2) {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: initial;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
      .price {
        font-size: 12px;
        font-weight: 400;
      }
      .price-all {
        margin-left: auto;
        flex-shrink: 0;
        font-size: 12px;
        line-height: 150%;
      }
      .amount {
        color: #ffa914;
        margin-left: 10px;
      }
      .currency {
        text-transform: uppercase;
      }
    }
    .error--unavailable--mid span {
      margin: 0 5px;
      font-weight: 600;
    }
    .error--amount {
      display: none;
      margin-top: 20px;
      color: #fc4e4e;
      font-size: 14px;
      line-height: 150%;
      &--display {
        display: block;
      }
      &--mid {
        font-weight: 600;
        span {
          margin-right: 5px;
        }
      }
    }
  }

  .product-item {
    width: 100%;
    display: flex;
    padding: 42px 0;
    border-bottom: 1px solid #bdbdbd;
    &:last-child {
      padding-bottom: 0;
      border-bottom: 0;
    }
    &--image {
      max-width: 143px;
      height: 119px;
      object-fit: cover;
      border-radius: 4px;
      @media (max-width: 756px) {
        max-width: 69px;
        max-height: 57px;
      }
    }
    &--left {
      display: flex;
      z-index: 4;
    }
    .text {
      margin-left: 26px;
      .head {
        font-size: 14px;
        font-weight: 600;
        line-height: 150%;
        text-transform: uppercase;
        margin-bottom: 17px;
        @media (max-width: 756px) {
          font-size: 10px;
        }
      }
      .description {
        font-size: 10px;
        color: #b0b0b0;
        line-height: 150%;
      }
    }
    &--right {
      margin-left: auto;
      display: flex;
      flex-direction: column;
    }
    .price {
      font-weight: 600;
      font-size: 24px;
      @media (max-width: 756px) {
        font-size: 14px;
        text-align: right;
      }
    }
    .currency {
      text-transform: uppercase;
      margin-left: 8px;
    }
    .input-counter {
      margin-left: auto;
      height: 30px;
      margin-top: 20px;
      @media (max-width: 756px) {
        margin-bottom: 20px;
      }
      .decrease {
        border-right: 0;
        margin-right: -2px;
        padding: 0 8px;
      }
      .increase {
        border-left: 0;
        margin-left: -2px;
        padding-left: 8px;
      }
      .button-disabled {
        color: #d6d6d6;
        pointer-events: none;
      }
    }

    .actions {
      display: flex;
      font-size: 12px;
      font-weight: 500;
      margin-left: auto;
      margin-top: auto;
    }
    .delete {
      border-left: 1px solid #bdbdbd;
      color: #fc4e4e;
      padding-left: 5px;
      cursor: pointer;
    }
    .watch {
      padding-right: 5px;
      font-weight: 400;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      &--disabled {
        pointer-events: none;
        cursor: none;
        color: #c4c4c4;
        .icon path {
          stroke: #c4c4c4;
        }
      }
      .icon {
        margin: 0 4px;
      }
    }
  }
}

.address-box {
  display: flex;
  flex-direction: column;
  position: relative;
  div {
    display: flex;
    flex-direction: column;
  }
  &--border {
    border: 1px solid #c4c4c4;
    padding: 20px;
    border-radius: 6px;
    cursor: pointer;
    height: calc(100% - 30px);
  }
  &__wrapper {
    display: flex;
    justify-content: space-between;
    @media (max-width: 756px) {
      flex-direction: column;
    }
  }
  &--faded {
    color: rgba(0, 0, 0, 0.4);
  }
  &--active {
    border: 1px solid #ffa914;
    &:before {
      content: '';
      width: 27px;
      height: 27px;
      position: absolute;
      right: -12px;
      top: -12px;
      background-color: #ffa914;
      border-radius: 20px;
    }
    &:after {
      content: '';
      top: -4px;
      right: -2px;
      width: 4px;
      height: 7px;
      border: solid #fff;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg);
      position: absolute;
      border-radius: 1px;
      z-index: 9;
    }
  }
  &__content {
    width: calc(50% - 40px);
    @media (max-width: 756px) {
      width: 100%;
      &:last-child {
        margin-top: 20px;
      }
    }

    p {
      font-size: 12px;
      color: rgba(0, 0, 0, 0.5);
      line-height: 18px;
      margin-bottom: 7px;
      font-weight: 500;
    }
  }
  &--new-address {
    color: rgba(255, 169, 20, 1);
    font-size: 14px;
    line-height: 150%;
    font-weight: 600;
    margin-top: 15px;
    cursor: pointer;
  }

  span {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    word-break: break-all;
  }
}

.seller-box {
  &:last-child {
    .seller-box--wrapper:last-child {
      border: none;
      .seller-box__product--wrapper:last-child {
        border: none;
        margin-bottom: 0;
      }
    }
  }
  &--wrapper {
    margin-bottom: 20px;
    padding-bottom: 30px;
    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }
  &--name {
    display: flex;
    align-items: flex-end;
    margin-bottom: 21px;
    p {
      font-size: 14px;
      line-height: 150%;
      color: #000000;
      font-weight: 600;
      margin-right: 15px;
    }
    span {
      font-size: 14px;
      color: #ffa914;
      line-height: 150%;
    }
  }
  &__product {
    &--wrapper {
      border-bottom: 1px solid #eeeeee;
      margin-bottom: 20px;
      padding: 10px 10px 30px 10px;
      position: relative;
      &--foreign {
        padding-bottom: 0;
      }
      @media (max-width: 756px) {
        padding: 10px 10px 30px 10px;
      }
    }
    &--error {
      border: 1px solid #fc4e4e !important;
      border-radius: 8px;
      &--more-than-zero {
        .seller-box__product--error-amount {
          display: block;
        }
        .input-counter .input {
          color: #fc4e4e;
        }
      }
      &-unavailable {
        .seller-box__product--error-unavailable {
          display: block;
        }
        .delete {
          display: block !important;
          margin-top: 40px;
        }
        .input-counter {
          display: none !important;
        }
      }
    }
    &--error-descript {
      margin-top: auto;
      color: #fc4e4e;
      font-size: 12px;
      display: none;
      text-transform: uppercase;
    }
    &--descript {
      display: flex;
      flex-direction: column;
      text-transform: capitalize;
      margin-top: 17px;
      div {
        display: flex;
      }
      p,
      span {
        line-height: 150%;
        color: #b0b0b0;
        font-weight: 400;
        font-size: 10px;
      }
      span {
        margin-left: 3px;
      }
    }
    &__amount {
      text-align: right;
      margin-top: 15px;
      font-weight: 500;
      font-size: 14px;
      color: #929292;
    }
    &__all-price {
      text-align: right;
      margin-top: auto;
      font-weight: 600;
      font-size: 24px;
      line-height: 150%;
      .currency {
        text-transform: uppercase;
      }
    }
    &__price {
      font-weight: 600;
      font-size: 16px;
      line-height: 150%;
      text-align: right;
      text-transform: uppercase;
      @media (max-width: 756px) {
        font-size: 14px;
      }
      &--wrapper {
        margin-left: auto;
        display: flex;
        flex-direction: column;
        .input-counter {
          height: 30px;
          margin-top: 20px;
          display: flex;
          justify-content: flex-end;
          .button-disabled {
            color: #d6d6d6;
            pointer-events: none;
          }
        }
        .delete {
          text-align: right;
          color: #fc4e4e;
          cursor: pointer;
          font-size: 12px;
          display: none;
        }
      }
    }
    &--mid {
      margin-left: 26px;
      text-transform: uppercase;
      line-height: 150%;
      font-size: 14px;
      font-weight: 600;
      display: flex;
      flex-direction: column;
      @media (max-width: 756px) {
        margin-left: 13px;
        font-size: 10px;
      }
    }
    &--item {
      display: flex;
    }
    &--image-box {
      width: 143px;
      height: 119px;
      border-radius: 4px;
      @media (max-width: 756px) {
        width: 69px;
        height: 57px;
      }
    }
    &--image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 4px;
    }
  }
  .delivery {
    margin-top: 43px;
    .delivery__item:last-child {
      margin-top: 20px;
    }
    &--hidden {
      opacity: 0;
      height: 0;
      overflow: hidden;
    }
    &--group {
      display: none;
      &:last-child {
        display: flex;
      }
    }
    &__arrow {
      display: flex;
      justify-content: center;
      cursor: pointer;
      transition: 0.2s;
      position: relative;
      &:after {
        content: '';
        top: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 9;
      }
      &--active {
        transform: scaleY(-1);
        transition: 0.2s;
      }
      &--hidden {
        display: none;
      }
      &--show {
        display: flex;
      }
    }
    &__wrapper {
      .regular-field {
        input {
          font-size: 14px;
          &::placeholder {
            text-decoration: unset;
            color: #cbcbcb;
          }
        }
        label {
          color: #272727;
        }
      }
    }
    &--error {
      font-size: 14px;
      color: #fc4e4e;
      margin-top: 25px;
      margin-left: 5px;
    }
    &__list {
      .delivery__item {
        &:nth-child(n + 4) {
          display: none;
        }
      }
      &--hidden-all {
        max-height: 0;
        overflow: hidden;
      }
      &--error {
        font-size: 12px;
        color: #fc4e4e;
        display: none;
        margin-top: 15px;
        &--active {
          display: block;
        }
      }
      &--active {
        max-height: 1500px;
        overflow: auto;
        overflow-y: hidden;
        transition: 0.5s;
        .delivery__item {
          &:nth-child(n + 4) {
            display: flex;
          }
        }
      }
      &--group {
        margin-bottom: 60px;
        padding: 0 10px;
        .delivery__item {
          &:nth-child(n + 4) {
            display: flex;
          }
        }
        @media (max-width: 756px) {
          padding: 0;
        }
      }
    }
    &__gray {
      display: flex;
      justify-content: space-between;
      color: #929292;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0.022em;
      margin-top: 24px;
      margin-bottom: 40px;
      @media (max-width: 756px) {
        flex-direction: column;
      }
      p {
        &:first-child {
          width: 60%;
          @media (max-width: 756px) {
            width: 100%;
          }
        }
        // &:last-child {
        //   width: 40%;
        //   @media (max-width: 756px) {
        //     width: 100%;
        //   }
        // }
      }
    }
    &__item {
      display: flex;
      align-items: flex-end;
      @media (max-width: 756px) {
        align-items: flex-start;
      }
      position: relative;
      cursor: pointer;
      &:first-child {
        margin-top: 5px;
      }
      &:before {
        content: '';
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 9;
      }
      padding-left: 40px;
      &:after {
        content: '';
        width: 16px;
        height: 16px;
        border-radius: 20px;
        position: absolute;
        border: 1px solid #dddddd;
        transition: 0.3s;
        left: 5px;
        top: 4px;
      }
      &-set {
        display: flex;
        max-width: 573px;
        margin-top: 19px;
      }
      &--error:after {
        border: 1px solid #fc4e4e;
      }
      &--error--active {
        display: block;
        color: #fc4e4e;
      }
      &--margin {
        margin: 37px 0;
        &:first-child {
          margin-top: 0;
        }
      }
      &--active {
        pointer-events: none;
        &:after {
          background-color: #ffa914;
          border: 1px solid #ffa914;
        }
        &:before {
          content: '';
          top: 6px;
          left: 7px;
          width: 8px;
          height: 8px;
          border-radius: 20px;
          border: 3px solid white;
          background-color: #ffa914;
          z-index: 9;
        }
      }
      &--description {
        margin-top: 10px;
        margin-bottom: 10px;
        font-size: 11px;
        color: rgba(0, 0, 0, 0.5);
        line-height: 16px;
      }
      &__image {
        width: 100%;
        height: 100%;
        &--wrapper {
          margin-bottom: auto;
          width: 51px;
          height: 25px;
        }
      }
      &--label {
        display: block;
        width: 60%;
        position: relative;
        padding-left: 15px;
        font-size: 14px;
        letter-spacing: 0.022em;
        margin-bottom: auto;
        @media (max-width: 756px) {
          padding-right: 8px;
        }
        @media (min-width: 756px) {
          margin-top: 4px;
        }
      }
      &--price-wrapper {
        position: relative;
        margin-left: auto;
        text-align: center;
        align-self: flex-start;
        &--description {
          margin-top: 32px;
          @media (max-width: 756px) {
            padding-right: 28px;
          }
        }
      }
      &--currency {
        margin-left: 5px;
        font-size: 14px;
      }
      &--price {
        max-width: 126px;
      }
      &--hidden {
        padding: 0;
        margin: 0;
        display: none;
      }
    }
  }
}

.checkout-product {
  display: flex;
  margin-top: 20px;
  &__image {
    width: 100%;
    height: 100%;
    &--container {
      width: 143px;
      height: 119px;
      border: 1px solid #eeeeee;
      border-radius: 4px;
      margin-right: 26px;
    }
  }
  &__mid {
    &--name {
      font-weight: 600;
      font-size: 14px;
      line-height: 150%;
      max-width: 180px;
      text-transform: uppercase;
    }
  }
  &__price {
    font-weight: 600;
    font-size: 24px;
    line-height: 150%;
    .currency {
      text-transform: uppercase;
    }
    &--container {
      margin-left: auto;
    }
  }
}

.address-form {
  display: flex;
  @media (max-width: 756px) {
    flex-direction: column;
  }
  &--left,
  &--right {
    width: 100%;
  }
  .css-g1d714-ValueContainer {
    overflow: unset;
  }
  &--left {
    @media (min-width: 756px) {
      margin-right: 17px;
    }
  }
  &--right {
    @media (min-width: 756px) {
      margin-left: 17px;
    }
  }
  &--modal {
    flex-direction: column;
  }
  .advertisements__filters--sort {
    min-width: 80px;
    width: 100%;
  }
  input,
  input:focus {
    border: 0.5px solid #dddddd;
  }
  .regular-field input {
    height: 40px;
  }
  .regular-field__label,
  .select-field__label {
    color: #272727;
    text-transform: uppercase;
  }
  .advertisements__filters--sort {
    margin: 0;
  }
  .buttons {
    margin-top: 35px;
  }
  .one-line {
    display: flex;
    .regular-field {
      width: 100%;
    }
    .select-field__wrapper {
      max-width: 40%;
      margin-right: 20px;
    }
    .react-select__control {
      box-shadow: none;
    }
    .svg path {
      fill: #bdbdbd;
    }
    .regular-field:first-child {
      margin-right: 20px;
      max-width: 40%;
    }
  }
}

.checkout {
  .right--section--sticky {
    position: relative;
    display: flex;
    flex-direction: flex-end;
    width: unset;
    align-items: flex-end;
    padding-bottom: 50px;
    width: 30%;
    .right {
      margin-left: 0;
      width: 100%;
    }
  }
  .left {
    @media (min-width: 756px) {
      margin-bottom: 49px;
    }
  }
}
