.filters {
  &__icon {
    cursor: pointer;
    width: 20px;
    height: 20px;
    &--wrapper {
      display: flex;
      justify-content: flex-end;
      margin-top: -25px;
    }
  }
  &__content {
    position: fixed;
    top: -195px;
    left: 0;
    width: 100%;
    height: calc(100vh + 200px);
    background-color: #f5f5f5;
    z-index: 999;
    padding: 0 20px;
    overflow: hidden;
    padding-top: 60px;
  }

  &--close {
    position: absolute;
    right: 25px;
    top: 42px;
    width: 14px;
    height: 14px;
    cursor: pointer;
    line,
    path {
      stroke: black;
    }
  }
}
