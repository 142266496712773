.price-management {
  &--partner-list {
    .select-field__label {
      top: -5px;
    }
  }
  &__content {
    width: 100%;
    .user-management__table {
      &__content {
        &--uppercase {
          text-transform: uppercase;
        }
        .rt-td:nth-child(4) {
          overflow: visible;
        }
      }
    }
  }
  &__countries {
    &--wrapper {
      margin-top: 36px;
    }
    &--heading {
      display: flex;
      border-bottom: 1px solid #eeeeee;
      padding-bottom: 21px;
      margin-bottom: 39px;
      &--country {
        margin-left: 10px;
      }
      &--earnings {
        margin-left: 75px;
      }
      &--earnings-foreign {
        margin-left: 150px;
      }
      &--currency {
        margin-left: 150px;
      }
    }

    &--heading-mobile {
      display: flex;
      font-size: 12px;
      gap: 60px;
      margin-left: 50px;

      &--earnings-foreign {
        position: relative;
        right: 20px;
      }
    }
  }
  &__settings {
    right: 10px;
  }
  &__table {
    &--heading {
      padding-left: 15px !important;
      &--price {
        margin-left: 100px;
      }
      p {
        text-transform: capitalize;
      }
    }
    .rt-tbody {
      padding-bottom: 50px;
    }

    @media (max-width: 765px) {
      .rt-tr-group {
        margin-top: 15px;
        .rt-tr {
          height: 40px;
        }
      }
    }
  }
  &__half {
    display: flex;
    justify-content: space-between;
    max-width: 326px;
    margin: 0 auto;
    .regular-field__label,
    .select-field__label {
      color: #272727;
    }
    .regular-field {
      margin-right: 20px;
    }
    .regular-field,
    .select-field__wrapper {
      width: 153px;
    }
  }
  &--form {
    display: flex;
    flex-direction: column;
  }
  .exchange {
    &--header {
      color: #000000;
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 14px;
      @media (max-width: 756px) {
        font-size: 10px;
      }
    }
    &__item {
      display: flex;
      align-items: center;
      @media (max-width: 756px) {
        font-size: 14px;
      }
      .icon {
        margin: 0 10px;
      }
    }
    &__input {
      border: 0px;
      padding: 0;
      margin: 0;
      height: 100%;
      font-size: 14px;
      font-weight: 400px;
      outline: none !important;
      .regular-field__label-error {
        position: absolute;
        width: 150px;
      }
      input {
        background-color: transparent;
        height: 33px;
        padding: 0 10px;
        &:focus,
        &:focus-visible {
          outline: none;
          border: 0px;
        }
      }
      &--wrapper {
        border: 1px solid #dddddd;
        border-radius: 4px;
        width: 110px;
        height: 33px;
        display: flex;
        align-items: center;
        padding-right: 5px;
      }
      &--currency {
        border-left: 1px solid #dddddd;
        padding-left: 4px;
        color: PLN;
        font-size: 14px;
        line-height: 24px;
      }
    }
  }
}

.productNameWrapper {
  display: flex;
  justify-content: flex-start;
  gap: 23px;
  margin-left: 10px;

  @media (max-width: 756px) {
    align-items: center;
  }

  .productPhoto {
    width: 82px;
    height: 68px;
    border-radius: 10px;
    &--hidden {
      visibility: hidden;
    }
  }

  .productName {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    width: 172px;

    @media (max-width: 756px) {
      font-size: 10px;
      line-height: 14px;
    }
  }
}
