.category-searcher {
  // margin-top: 10px;
  &--input input {
    border-radius: 4px;
    background-color: #fff;
    border: 1px solid #d5d5d552;
    color: #d5d5d5;
    font-size: 14px;
    font-weight: 500;
    padding-left: 35px;
  }
  svg {
    left: 10px !important;
  }
}
