.product-lists {
  margin: 50px 0;
  display: flex;
  @media (max-width: 1100px) {
    .single-tile {
      width: 100%;
    }
  }
  @media (max-width: 756px) {
    display: block;
    margin-top: 30px;
  }
  &__content {
    margin-top: -39px;
    max-width: 687px;
    display: grid;
    width: 100%;
    align-content: flex-start;
    @media (max-width: 1100px) {
      width: 100%;
      max-width: 100%;
    }
    @media (max-width: 756px) {
      margin-top: 0;
      display: flex;
      flex-direction: column;
    }
  }
  .single-tile {
    margin-top: 17px;
    margin-bottom: 27px;
    @media (max-width: 756px) {
      margin: 80px 0;
    }
  }
  &__breadcrumb {
    margin: 12px 0 12px 17px;
    z-index: 9;
    margin-right: 30px;
    &--wrapper {
      display: flex;
      flex-direction: column;
    }
  }
}
