.map-section {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 20px;
  row-gap: 15px;
  column-gap: 24px;
  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 756px) {
    grid-template-rows: 1fr 50px 263px;
    grid-template-columns: 1fr;
  }
  .single-tile {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    &--orange .btn {
      max-width: 170px;
      width: auto;
      padding: auto;
      padding: 8px 26px 10px 26px;
    }
    &--light-orange {
      @media (max-width: 756px) {
        min-height: 450px;
      }
    }
    &--orange {
      @media (max-width: 756px) {
        max-height: 250px;
      }
    }
  }
  &__map {
    grid-column: 1/3;
    @media (max-width: 1024px) {
      display: none;
      grid-column: unset;
    }
  }
  &__biznes {
    grid-column: 1;
    grid-row: 2/2;
    @media (max-width: 756px) {
      display: none;
    }
  }
  &__guaranted {
    grid-column: 2/3;
    @media (max-width: 1024px) {
      grid-column: 1/2;
    }
    @media (max-width: 756px) {
      grid-column: 1/2;
      grid-row: 3/4;
    }
  }
  &__invite {
    grid-column: 3/4;
    grid-row: 1/3;
    @media (max-width: 1024px) {
      grid-column: 2/3;
    }
    @media (max-width: 756px) {
      grid-column: 1/2;
      grid-row: 1/3;
    }
  }
}
