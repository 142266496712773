.user-management {
  font-family: 'Poppins';

  &__heading {
    display: flex;
    justify-content: space-between;
    margin-bottom: 29px;
    @media (max-width: 756px) {
      flex-direction: column;
    }
  }
  &--heading {
    font-size: 18px;
    font-weight: 500;
    @media (max-width: 756px) {
      margin-bottom: 30px;
      font-size: 14px;
    }
  }
  &__wrapper {
    width: 100%;
    min-height: calc(100vh - 50px);
    display: flex;
    padding-top: 66px;
    flex-wrap: wrap;
    align-content: flex-start;
    @media (max-width: 756px) {
      padding-top: 40px;
    }
  }
  &__top {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  &__content {
    &--allegro {
      display: flex;
      justify-content: center;
    }
    &--svg {
      margin-left: 27px;
    }
    &--premium {
      color: #ffa914;
      font-weight: 700;
      &::first-letter {
        text-transform: uppercase;
      }
    }
    &--standard::first-letter {
      text-transform: uppercase;
    }
    width: 100%;
    .rt-tbody {
      padding-bottom: 100px;
    }
    .pagination-bottom {
      margin-bottom: 50px;
    }
  }

  &__menu {
    display: flex;
    height: 26px;
    @media (max-width: 1024px) {
      justify-content: space-between;
    }
    @media (max-width: 756px) {
      justify-content: unset;
      display: unset;
      width: 400px;
      margin-top: 20px;
    }
    &--item {
      font-size: 14px;
      color: #000000;
      font-weight: 500;
      line-height: 24px;
      cursor: pointer;
      display: flex !important;
      margin: 0 20px;
      &:first-child {
        margin-left: 0;
      }
      @media (max-width: 756px) {
        width: 100%;
      }
    }
    &--active {
      .label {
        font-weight: 600;
        border-bottom: 1.5px solid black;
      }
    }
  }
  &__empty {
    font-size: 14px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
    @media (max-width: 756px) {
      margin-top: 60px;
    }
    &--image {
      max-width: 275px;
      @media (max-width: 756px) {
        margin: 0 auto;
      }
    }
    &--header {
      margin-top: 40px;
      font-size: 18px;
      font-weight: 500;
      line-height: 27px;
    }
    &--descript {
      max-width: 572px;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      margin-top: 9px;
      @media (max-width: 756px) {
        text-align: center;
      }
    }
    &--button {
      margin-top: 30px;
    }
  }
  &__filters {
    &__wrapper {
      margin-top: 34px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      padding-bottom: 40px;
      margin-bottom: 36px;
      border-bottom: 1px solid #dddddd;
      &--orders {
        justify-content: flex-start;
        @media (max-width: 756px) {
          .btn {
            width: calc(100% - 15px);
          }
        }
      }
      @media (max-width: 756px) {
        justify-content: unset;
        padding-bottom: 34px;
        button {
          width: calc(50% - 10px);
        }
      }
      .btn {
        margin-left: 8px;
      }
    }
    &--product {
      .product-list-item__right {
        flex-shrink: unset;
      }
    }
    &--category,
    &--sort {
      width: 266px;
      margin: 0 8px;
      .select-field {
        border: 0.5px solid #dddddd;
        border-radius: 4px;
        &__label {
          color: #272727;
        }
      }
    }
  }
}

.user-management__table {
  &--wrapper {
    margin-top: 36px;
    padding-bottom: 100px;
  }

  &__heading {
    padding-left: 100px;
    display: flex;
    border-bottom: 1px solid #eeeeee;
    padding-bottom: 21px;
    margin-bottom: 39px;
    align-items: center;
    p {
      text-transform: lowercase !important;
    }

    @media (max-width: 756px) {
      display: none;
    }
    &--lang {
      margin-left: 175px;
    }
    &--item {
      padding: 0 63px;
    }
    &--name {
      margin-left: 63px;
      width: 176px;
    }
    &--partnerName {
      margin-left: 43px;
      width: 138px;
    }
    &--surname {
      width: 170px;
    }
    &--coordinatorName {
      width: 135px;
      margin-left: 93px;
    }
    &--coordinatorSurname {
      width: 165px;
    }
    &--coordinatorEmail {
      width: 245px;
    }
    &--coordinatorAdd {
      margin-left: auto;
    }
    &--conclusionName {
      width: 135px;
      margin-left: 43px;
    }
    &--country {
      width: 45px;
    }
    &--conclusionSurname {
      width: 165px;
    }
    &--conclusionEmail {
      width: 245px;
    }
  }

  &__headingPartner {
    padding-left: 143px;
    display: flex;
    border-bottom: 1px solid #eeeeee;
    padding-bottom: 21px;
    margin-bottom: 39px;
    align-items: center;
    p {
      text-transform: lowercase !important;
    }

    @media (max-width: 756px) {
      display: none;
    }
    &--partnerName {
      // margin-left: 43px;
      min-width: 100px;
    }
    &--partnerSurname {
      min-width: 150px;
    }

    &--partnerEmail {
      min-width: 200px;
      position: relative;
      // left: 30px;
    }

    &--partnerEmail {
      min-width: 200px;
    }

    &--partnerAllegro {
      min-width: 100px;
    }
    &--partnerEbay {
      min-width: 80px;
    }
    &--partnerPayments {
      width: 243px;
      position: relative;
      // left: 30px;
    }
    &--partnerCountry {
      min-width: 60px;
      position: relative;
      // left: 50px;
    }
  }

  &__settings {
    display: flex;
    justify-content: flex-end;
    position: relative;
    &--item {
      cursor: pointer;
      &:first-child {
        margin-right: 20px;
      }
      &--disabled {
        pointer-events: none;
      }
    }
    &__list {
      position: absolute;
      top: 14px;
      right: 0;
      padding: 7px 22px;
      background-color: #fff;
      border-radius: 4px;
      z-index: 9;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
      color: #000;
      &--item {
        text-transform: capitalize;
        line-height: 150%;
        padding: 6px 0;
        cursor: pointer;
        &--red {
          color: #fc4e4e;
        }
      }
    }
  }
}

.row-user-suspended {
  color: #929292;
  .badge {
    background-color: #b9b9b9;
  }
}

.partner-managment {
  .user-management__table {
    &__heading {
      @media (max-width: 756px) {
        margin: 0;
        padding: 0;
        display: flex;
        font-size: 12px;
        padding-bottom: 20px;

        &--conclusionName:nth-child(1) {
          position: relative;
          left: 108px;
        }

        &--conclusionName:nth-child(2) {
          position: relative;
          left: 50px;
        }
      }
    }

    &__content {
      &--desktop {
        @media (max-width: 756px) {
          display: unset;
        }
      }
    }
  }
}

.product-managment {
  .user-management__table {
    &__heading {
      display: flex;
      justify-content: flex-start;
      gap: 150px;
      &--price {
        margin-left: 180px;
      }
      @media (max-width: 756px) {
        margin: 0;
        padding: 0;
        gap: 30px;
        padding-bottom: 20px;
        font-size: 12px;
        border-bottom: none;

        &--price {
          margin-left: 60px;
        }
      }
    }
    &__content {
      .rt-td:nth-child(2) {
        padding-bottom: 30px;
      }
      .rt-td:nth-child(3) {
        padding-bottom: 30px;
      }
      .rt-td:nth-child(4) {
        padding-bottom: 40px;
      }
      @media (max-width: 756px) {
        .rt-td:nth-child(2) {
          padding-bottom: unset;
        }
        .rt-td:nth-child(3) {
          padding-bottom: unset;
        }
        .rt-td:nth-child(4) {
          padding-bottom: unset;
        }
      }
    }
  }
}
.user-management__table {
  &__content {
    &--uppercase {
      text-transform: uppercase;
    }
    .rt-td:nth-child(2) {
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .rt-td:nth-child(3) {
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .rt-td:nth-child(4) {
      text-overflow: ellipsis;
      overflow: hidden;
    }
    @media (max-width: 756px) {
      .rt-td:nth-child(2) {
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .rt-td:nth-child(3) {
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .rt-td:nth-child(4) {
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
}
