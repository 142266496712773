.contact {
  min-height: calc(100vh - 355px);
  padding-top: 140px;
  @media (max-width: 786px) {
    padding-top: 71px;
  }
  &--ilustration {
    margin-top: 145px;
    max-width: 300px;
    @media (max-width: 786px) {
      max-width: 300px;
      margin: 40px auto 10px auto;
      &--wrapper {
        display: flex;
        justify-content: center;
      }
    }
  }
  .agreement {
    color: rgba(145, 145, 145, 1);
    font-size: 10px;
    line-height: 150%;
  }
  .underline {
    text-decoration: underline;
  }
  &--top {
    display: flex;
    gap: 40px;
    @media (max-width: 786px) {
      flex-direction: column;
    }
  }
  &--bottom {
    margin: 87px 0;
    display: flex;
    gap: 40px;
    @media (max-width: 786px) {
      flex-direction: column;
    }
  }
  &--left {
    width: 40%;
    @media (max-width: 786px) {
      width: 100%;
    }
    h1 {
      font-size: 18px;
      font-weight: 500;
      line-height: 27px;
    }
    h2 {
      font-size: 18px;
      font-weight: 600;
      line-height: 27px;
    }
    .paragraph {
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 18px;
      &--container {
        display: flex;
        margin: 11px 0;
        .tel {
          margin-left: 20px;
          text-decoration: none;
          color: black;
        }
      }
    }
    hgroup {
      margin-bottom: 38px;
    }
    .orange {
      color: rgba(255, 169, 20, 1);
    }
    .bold {
      font-size: 16px;
      font-weight: 600;
      margin-left: 34px;
      text-decoration: none;
      color: black;
    }
  }
  &--right {
    width: 60%;
    @media (max-width: 786px) {
      width: 100%;
    }
  }
  &__form {
    margin-top: 44px;
    height: calc(100% - 20px);
    .paragraph {
      font-size: 10px;
      font-weight: 400;
      line-height: 150%;
      margin-top: 25px;
    }
    &--textarea textarea {
      height: 206px !important;
    }
    &--half {
      display: flex;
      gap: 20px;
      .regular-field {
        width: 100%;
      }
    }
    &--btn {
      display: flex;
      justify-content: center;
      margin-top: 35px;
      .btn {
        max-width: 330px;
        width: 100%;
      }
    }
    &--wrapper {
      background-color: #f5f5f5;
      height: 100%;
      border-radius: 16px;
      padding: 28px 59px 35px 59px;
      @media (max-width: 786px) {
        padding: 26px 17px 35px 17px;
      }
    }
    &--heading {
      font-size: 16px;
      font-weight: 500;
      text-align: center;
    }
    textarea {
      width: 100%;
      height: 137px;
      border: none;
      margin: 17px 0;
      resize: none;
      padding: 10px 20px;
    }
    .regular-field__label {
      color: rgba(39, 39, 39, 1);
      text-transform: uppercase;
    }
  }

  &--address {
    .mid {
      font-weight: 600;
    }
    .paragraph {
      font-size: 14px;
    }
    font-size: 14px;
  }
  &--nip {
    margin: 44px 0;
  }
}
