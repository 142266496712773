.pagination {
  background-color: green;
  &__wrapper {
    display: flex;
    justify-content: flex-end;
    @media (max-width: 756px) {
      justify-content: center;
    }
  }
  &__content {
    display: flex;
  }
  &__previous {
    transform: rotate(90deg);
    &--double {
      transform: rotate(0deg);
    }
  }
  &__next {
    transform: rotate(270deg);
  }
  &__item {
    font-size: 14px;
    width: 40px;
    color: #272727;
    height: 40px;
    border-radius: 4px;
    border: 1px solid #eeeeee;
    display: flex !important;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    flex-shrink: 0;
    margin: 0 2px;
    @media (max-width: 756px) {
      margin: 0 5px;
    }
    &--disabled {
      pointer-events: none;
      path {
        fill: #ececec;
      }
    }
    &--active {
      background-color: #eeeeee;
    }
  }
  &__numbers {
    display: flex;
  }
  &--ellipsis {
    border: 1px solid rgba(245, 245, 245, 0.7);
    cursor: auto;
  }
}
