.popular-categories {
  margin-bottom: 150px;
  &--name {
    margin-bottom: 42px;
    font-weight: 700;
    font-size: 18px;
    color: #363636;
    margin-top: 50px;
  }
  &__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    @media (max-width: 1000px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 756px) {
      grid-template-columns:  1fr;
    }
    // row-gap: 6px;
    // column-gap: 20px;
    margin-left: -20px;
  }
  .single-category-bar {
    padding-left: 34px;
    margin-bottom: 6px;
    margin-left: 20px;
  }
}
