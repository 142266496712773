.my-orders {
  &__wrapper {
    width: 100%;
    min-height: calc(100vh - 50px);
    display: flex;
    padding-top: 66px;
    flex-wrap: wrap;
    flex-direction: column;
    align-content: flex-start;
    margin-bottom: 50px;
    @media (max-width: 756px) {
      padding-top: 40px;
    }
    .title {
      width: 100%;
    }
    .advertisements__menu {
      max-width: 100%;
    }
  }
  &--table {
    &--wrapper {
      width: 100%;
    }
    .rt-tr-group .rt-tr {
      background-color: transparent;
      padding-right: 0;
    }
    .pagination-bottom {
      margin-top: 30px;
    }
  }
  &--counter {
    background-color: black;
    &--empty {
      background-color: transparent;
    }
  }
}

.order-item {
  &--payments-btn {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
  }
}
