.state-part {
  display: flex;
  &__item {
    font-weight: 600;
    font-size: 10px;
    line-height: 15px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    &:before {
      content: '';
      width: 5px;
      height: 5px;
      border-radius: 50%;
      margin-right: 8px;
    }
    &--used {
      &:before {
        background-color: #272727;
      }
    }
    &--new {
      color: #15c51c;
      &:before {
        background-color: #15c51c;
      }
    }
    &--magazine {
      color: #ffa914;
      &:before {
        background-color: #ffa914;
      }
    }
  }
}
