.two-tiles {
  display: flex;
  margin-top: 90px;
  z-index: -2;
  @media (max-width: 660px) {
    margin-top: 180px;
  }

  .single-tile {
    @media (max-width: 1100px) {
      &:first-child {
        flex-grow: 1;
        margin-right: 0;
      }
      &:nth-child(2) {
        display: none;
      }
    }
    &--double {
      margin-right: 24px;
    }
  }
}
