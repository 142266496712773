// FONT FAMILY
$font__poppins: 'Poppins', sans-serif;

// FONT WEIGHT
$weight__light: 300;
$weight__regular: 400;
$weight__medium: 500;
$weight__bold: 700;
$weight__black: 900;

// COLORS

// FONT COLORS
