.btn {
  font-size: 12px;
  font-weight: 500;
  padding: 8px 26px 10px 26px;
  background-color: transparent;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Poppins;
  &--not-allowed {
    pointer-events: none !important;
    cursor: not-allowed !important;
  }
  &--disabled {
    display: none;
  }
  &--disabled-gray {
    background-color: #c4c4c4;
    color: #fff;
    pointer-events: none;
  }
  &__icon {
    &--right {
      margin-left: 9px;
    }
  }

  &--watching--disabled {
    color: #c4c4c4;
    background-color: #f5f5f5;
    border-radius: 4px;
    pointer-events: none !important;
    cursor: auto;
    .svg path {
      stroke: #c4c4c4;
    }
  }

  &--orange {
    background-color: #ffa914;
    color: #fff;
    &--sharp {
      border-radius: 4px;
      &--disabled {
        color: #c4c4c4 !important;
        border: 1px solid #f5f5f5 !important;
        background: #f5f5f5 !important;
        pointer-events: none;
      }
    }
  }
  &--white {
    background-color: #fff;
    color: #272727;
    svg path {
      fill: #272727;
    }
  }

  &--submitted-gray {
    background-color: #f5f5f5;
    color: #000;
    &--sharp {
      border-radius: 4px;
    }
  }

  &--disabled-border {
    border: 1px solid #f5f5f5;
    color: #f5f5f5;
    background-color: transparent;
  }

  &--orange-border {
    border: 1px solid #ffa914;
    color: #ffa914;
    background-color: transparent;
    &--disabled {
      color: #c4c4c4 !important;
      border: 1px solid #f5f5f5 !important;
      background: #f5f5f5 !important;
      pointer-events: none;
    }
  }
  &--red-border {
    border: 1px solid #fc4e4e;
    color: #fc4e4e;
    background-color: transparent;
    &--disabled {
      color: #c4c4c4 !important;
      border: 1px solid #f5f5f5 !important;
      background: #f5f5f5 !important;
      pointer-events: none;
    }
  }

  &--orange-border-color-black {
    border: 1px solid #ffa914;
    color: #000000;
    border-radius: 5px;
    background-color: transparent;
    font-weight: 400;
    font-size: 18px;
    letter-spacing: 0.022em;
  }
  &--orange-fill-color-black {
    border: 1px solid #ffa914;
    color: #000000;
    border-radius: 5px;
    background-color: #ffa914;
    font-weight: 400;
    font-size: 18px;
    letter-spacing: 0.022em;
  }
  &--gray {
    background: #f5f5f5;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 400;
    padding: 10px 12px;
  }
  &--black-border {
    border-radius: 4px;
    border: 2px solid #272727;
  }
  &--white-border {
    border-radius: 50px;
    color: #fff;
    border: 1px solid #fff;
  }
  &__fit-chevrone {
    border: 1px solid rgb(2, 2, 2);
    padding: 0;
    width: 40px;
    height: 40px;
    svg {
      margin-right: -3px;
    }
    &--orange {
      border: 1px solid #fff;
    }
    &--light-orange {
      path {
        stroke: #ffa914;
      }
      border: 1px solid #ffa914;
    }
  }
  &--arrow-right {
    right: 15px;
    bottom: 15px;
  }
  &--arrow-left {
    position: absolute;
    // left: 15px;
    bottom: 15px;
  }
  &--invoice {
    position: absolute;
    right: 0;
    width: 66px;
    font-weight: 400;
    padding: 8px 11px;
    @media (min-width: 756px) {
      top: 30px;
     }
  }
}
