.main-categories {
  margin-top: 26px;
  &--name {
    margin-bottom: 42px;
    font-weight: 700;
    font-size: 18px;
    color: #363636;
  }
  &--list {
    display: flex;
    flex-wrap: wrap;
    // gap: 24px;
    margin-left: -20px;
    @media (max-width: 756px) {
      flex-direction: column;
    }
  }
  &--item {
    margin-bottom: 20px;
    margin-left: 20px;
    &--disabled {
      pointer-events: none;
      cursor: none;
      position: relative;
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(189, 189, 189, 0.5);
        border-radius: 16px;
      }
    }
  }
}
