.dictionary {
  min-height: calc(100vh - 45px);
  align-content: flex-start;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  padding-top: 66px;
  @media (max-width: 1024px) {
    margin-bottom: 100px;
  }
  &__top,
  &__content {
    width: 100%;
  }
  .btn--dictionary{
    margin-bottom: 10px;
  }
  &--word{
      font-size: 14px;
  }
  .menu {
    display: flex;
    margin: 30px 0;
    &--item {
      font-size: 16px;
      color: #000000;
      font-weight: 300;
      line-height: 24px;
      cursor: pointer;
      display: flex !important;
      margin: 0 20px;
      &:first-child {
        margin-left: 0;
      }
      @media (max-width: 756px) {
        width: 100%;
      }
    }
    &--active {
      .label {
        font-weight: 500;
        border-bottom: 1.5px solid black;
      }
    }
  }
  &__table__contntet {
    .rt-tbody{
      overflow: visible;
    }
    .rt-tr {
      display: grid !important;
      grid-template-columns: [word] 40% [svg] 10% [translation] 40% [settings] 10%;
      padding: 13px 20px 13px 20px !important;
      margin-left: 0 !important;
      width: 100% !important;
      .rt-td{
        overflow: visible;
      }
    }
  }
  .table {
    &__heading {
      margin-top: 30px;
      display: flex;
      border-bottom: 1px solid #eeeeee;
      padding-bottom: 10px;
      .left {
        padding-left: 30px;
      }
      .right{
        @media (max-width: 756px) {
          margin-left: 25px;
        }
        margin-left: 2%;
      }
      .left,
      .right {
        width: 50%;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        span {
          text-transform: capitalize;
        }
      }
    }
  }
}
.buttons-add-dictionary {
  display: flex;
  justify-content: space-around;
  margin-top: 35px;
  .btn {
    padding: 10px 42px;
    max-width: 200px;
    text-transform: capitalize;
  }
}
