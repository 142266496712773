.settings__phone-number {
  display: flex;
  gap: 10px;
  .select-field__wrapper {
    width: 200px;
    .react-select__indicator {
      padding: 2px;
    }
    .select-field__label {
      color: #272727;
      text-transform: uppercase;
    }
    path {
      fill: hsl(0, 0%, 80%);
    }
  }
}
#settings {
  .btn {
    width: max-content;
  }
  @media (max-width: 1024px) {
    .btn {
      width: 100%;
    }
  }
  .btn p {
    color: white !important;
  }
  .settings {
    &__my-data {
      &__wrapper {
        min-width: 50%;
        &--top {
          @media (min-width: 1024px) {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            max-height: 16px;
            margin-bottom: 25px;
            column-gap: 30px;
          }
          p {
            display: flex;
            align-items: center;
            max-height: inherit;
          }
          .btn {
            margin-top: -10px;
            @media (max-width: 1024px) {
              margin-top: 23px;
            }
            border-radius: 4px;
            background-color: #f5f5f5;
            p {
              color: black !important;
            }
          }
        }
      }
    }
    &__wrapper {
      @media (max-width: 1024px) {
        flex-direction: column;
        // align-items: center;
        gap: 50px;
      }
      display: flex;
      // justify-content: space-between;
      width: 100%;
      min-width: 50%;
      // gap: 200px;
      margin-top: 30px;
    }
    &__content {
      &__country-menu {
        position: relative;
        display: flex;
        gap: 30px;
        cursor: pointer;
        font-weight: 300;
        font-size: 16px;
        margin-top: 40px;
        .active {
          font-weight: 500;
          border-bottom: 1.5px solid black;
          padding-bottom: 5px;
          width: fit-content;
        }
      }
      &--address {
        grid-template-columns: repeat(2, 1fr);
      }
      &--billing {
        grid-template-columns: 1fr 1fr;
        margin-top: 10px;
        grid-template-rows: repeat(5, 1fr);
        .settings__content__item--empty {
          @media (max-width: 756px) {
            display: none;
          }
        }
      }
      @media (max-width: 1024px) {
        display: flex;
        flex-direction: column;
      }
      display: grid;
      grid-auto-flow: column;
      grid-template-rows: [row1] 72px [row2] 72px [row3]72px [row4]72px;
      @media (min-width: 756px) {
        column-gap: 30px;
      }
      &__item {
        flex: 1 0 21%;
        gap: 5px;
        // width: 330px;
        // margin: 16px 0px;
        padding: 23px 0;
        p:first-child {
          font-weight: 500;
          margin-bottom: 5px;
          font-size: 12px;
          color: #b0b0b0;
          text-transform: uppercase;
        }
        p {
          font-weight: 400;
          font-size: 14px;
          color: #000000;
        }
      }
    }
    &__submit {
      align-self: flex-start;
      margin-top: 5px;
      @media (min-width: 1024px) {
        width: 45%;
      }
    }
    &__allegro {
      @media (max-width: 1024px) {
        width: 100%;
        text-align: center;
        justify-content: center;
      }
      margin-top: 23px;
      gap: 3px;
      font-weight: 600;
      font-size: 14px;
      padding: 11px 21px;
      border: 1px solid #272727;
      width: max-content;
      border-radius: 4px;
      position: relative;
      display: flex;
      align-items: center;
      cursor: pointer;
      &:hover .settings__allegro--tooltip {
        visibility: visible;
        transition: 0.3s;
      }
      &--active {
        cursor: auto;
        border: 0;
        padding-left: 0;
      }
      &--tooltip {
        position: absolute;
        visibility: hidden;
        left: 130%;
        top: 0;
        background-color: #eeeeee;
        border-radius: 4px;
        width: 138px;
        font-size: 10px;
        transition: 0.3s;
        padding: 10px;
        &--icon {
          position: absolute;
          top: 0;
          right: -24px;
        }
        &::after {
          content: ' ';
          position: absolute;
          top: 0%; /* At the bottom of the tooltip */
          right: 100%;
          border-width: 10px;
          border-style: solid;
          border-color: #eeeeee transparent transparent transparent;
          transform: rotate(90deg);
        }
      }
      &--allegro {
        height: 20px;
        svg {
          height: 22px;
        }
      }
    }
    &__form {
      .regular-field__input {
        @media (max-width: 1024px) {
          width: 100%;
        }
        input {
          border: 1px solid #dddddd;
          &:focus,
          :active {
            border: 1px solid rgba(255, 169, 20, 0.5);
            padding: 11px 22px;
          }
        }
        label {
          font-style: normal;
          font-weight: 500;
          font-size: 10px;
        }
      }
    }
  }
}

.biling__modal {
  .modal-actions__content {
    max-width: 100%;
  }
}
