.single-category {
  background-color: #f5f5f5;
  min-height: 126px;
  width: 330px;
  display: flex;
  border-radius: 16px;
  padding: 19px 22px;
  flex-grow: 1;
  position: relative;
  cursor: pointer;
  @media (max-width: 756px) {
    width: 100%;
  }
  &--disabled {
    background-color: #eaeaea;
    pointer-events: none;
  }
  &--left {
    display: flex;
    flex-direction: column;
  }
  &__right {
    &--image {
      position: absolute;
      // top: 7px;
      // top: -25px;
      right: 22px;
      max-width: 150px;
      max-height: 112px;
      top: 50%;
      transform: translate(0, -50%);
    }
  }
  &--label {
    font-size: 14px;
    color: #262626;
    line-height: 21px;
    font-weight: 500;
    max-width: 100px;
    text-transform: capitalize;
  }
  &--quantity {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: #919191;
    margin-top: auto;
  }
}
