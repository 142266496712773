.lists-products {
  display: flex;
  flex-direction: column;
  width: 100%;
  &__table {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 10px;
    .rt-table {
      border-top: 1px solid #eee;
      margin-top: 20px;
      @media (max-width: 756px) {
        border-top: 1px solid #eee;
        margin-top: 20px;
      }
    }
  }
  .pagination-top {
    margin-top: 50px;
    @media (max-width: 756px) {
      margin-top: 45px;
    }
  }
  // .pagination-bottom {
  //   .-previous button {
  //     width: 40px;
  //     height: 40px;
  //     position: relative;
  //     border: 0;
  //     background-color: transparent;
  //     border: 1px solid #eeeeee;
  //     border-radius: 4px;
  //     svg {
  //       transform: rotate(90deg);
  //     }
  //     &:disabled {
  //       border: 0;
  //       path {
  //         fill: #ececec;
  //       }
  //     }
  //   }
  //   .-next button {
  //     width: 40px;
  //     height: 40px;
  //     position: relative;
  //     border: 0;
  //     background-color: transparent;
  //     border: 1px solid #eeeeee;
  //     border-radius: 4px;
  //     svg {
  //       transform: rotate(270deg);
  //     }
  //     &:disabled {
  //       border: 0;
  //       path {
  //         fill: #ececec;
  //       }
  //     }
  //   }
  // }
}
