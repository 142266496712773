.single-tile {
  width: 330px;
  // height: 227px;
  border-radius: 16px;
  padding: 35px 35px 24px 25px;
  position: relative;
  display: flex;
  justify-content: space-between;
  .btn--orange-border {
    margin-top: 40px;
  }
  &--double {
    width: 684px;
    @media (max-width: 660px) {
      padding-right: 0;
      width: 100%;
    }
  }
  &__header {
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    width: 243px;
    &--double {
      max-width: 325px;
      width: 100%;
    }
  }
  &__paragraph {
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    &:nth-child(2) {
      margin-top: 10px;
      max-width: 300px;
    }
    &:nth-child(3) {
      margin-top: 12px;
      max-width: 230px;
    }
    &--double {
      width: 231px;
      margin-top: 10px;
    }
  }
  &--orange {
    background-color: #ffa914;
    h2 {
      color: #fff;
    }
    p {
      color: #fff;
    }
  }
  &--light-orange {
    background-color: #fff8f1;
    h2 {
      color: #ffa914;
    }
    p {
      color: #ffa914;
    }
  }
  &--shield {
    position: absolute;
    top: -10px;
    right: -70px;
  }
  &--engine {
    width: 271px;
    height: 275px;
    margin-top: -100px;
    @media (max-width: 660px) {
      margin-top: -200px;
    }
    @media (max-width: 560px) {
      margin-left: -90px;
    }
    @media (max-width: 480px) {
      margin-left: -150px;
    }
  }
  &--mirror {
    position: absolute;
    bottom: 10px;
    right: 10px;
  }
  &--motorman {
    position: absolute;
    bottom: 0;
    right: 0;
  }
  &--guaranted {
    display: none;
    &__wrapper {
      @media (max-width: 756px) {
        height: 176px;
      }
    }
    @media (max-width: 756px) {
      display: block;
      position: absolute;
      right: 0;
    }
  }
  @media (max-width: 756px) {
    .btn--arrow-right {
      left: 15px;
    }
    .btn--arrow-left {
      right: 15px;
      left: unset;
    }
  }
}
