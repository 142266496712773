.item-condition {
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 756px) {
    flex-direction: column;
    margin: 0 auto;
  }
  &__wrapper {
    display: flex;
    margin-top: 30px;
    position: relative;
    @media (max-width: 756px) {
      flex-direction: column;
    }
    .btn {
      margin-right: 18px;
      @media (max-width: 756px) {
        width: 100%;
        max-width: 213px;
        min-width: 180px;
        margin: 10px 0;
      }
    }
  }
  &--errors {
    color: #fc4e4e;
    font-size: 14px;
    margin-top: 14px;
    margin-bottom: -5px;
    width: 100%;
  }
}
