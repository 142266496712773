.skeleton-product {
  &--wrapper {
    border: 1px solid #f3f3f3;
    border-radius: 8px;
    padding: 20px 23px;
    display: flex;
    gap: 24px;
    margin-bottom: 20px;
  }
  &--left {
    width: 174px;
    height: 133px;
    background-color: #eeeeee;
    border-radius: 4px;
  }
  &__mid {
    width: 199px;
    height: 100%;
    display: flex;
    flex-direction: column;
    &--top {
      margin-bottom: 20px;
    }
    &--bottom {
      margin-top: auto;
    }
    &--mid {
      .react-loading-skeleton:first-child {
        margin-bottom: 5px;
      }
    }
  }
  &__right {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    width: 94px;
    &--bottom {
      margin-top: auto;
    }
  }
}

.skeleton--dark {
  background-color: #d3d3d3 !important;
  background-image: linear-gradient(90deg, #d3d3d3, #dedede, #d3d3d3) !important;
}
