.product-sidebar {
  width: 330px;
  margin-right: 20px;
  @media (max-width: 1100px) {
    display: none;
  }
  &__change-category {
    h3 {
      font-weight: 700;
    }
  }
  &__change-category {
    cursor: auto;
    pointer-events: none;
  }
  &--change {
    margin-top: auto;
    font-weight: 400;
    font-size: 12px;
    color: #919191;
    display: flex;
    align-items: center;
    cursor: pointer;
    pointer-events: all;
    &--icon {
      transform: rotate(90deg);
      margin-right: 5px;
    }
  }
  &__list-categories {
    margin-top: 10px;
    .single-category-bar {
      margin-top: 10px;
    }
  }
  &__subcategory {
    font-size: 12px;
    color: #19191d;
    font-weight: 400;
    line-height: 18px;
    padding: 15px 55px;
    display: flex;
    justify-content: space-between;
    position: relative;
    cursor: pointer;
    &:after {
      content: '';
      width: 16px;
      height: 16px;
      border-radius: 3px;
      position: absolute;
      border: 1px solid #dddddd;
      right: 20px;
      transition: 0.3s;
    }
    &--active {
      &:after {
        background-color: #ffa914;
        border: 1px solid #ffa914;
      }
      &:before {
        content: '';
        top: 17px;
        right: 25px;
        width: 4px;
        height: 7px;
        border: solid white;
        border-width: 0 3px 3px 0;
        transform: rotate(45deg);
        position: absolute;
        border-radius: 1px;
        z-index: 9;
      }
    }
    &__wrapper {
      visibility: hidden;
      transition: 0.4s;
      max-height: 0;
      overflow: hidden;
      &--active {
        visibility: visible;
        max-height: 1000px;
        transition: 0.4s;
      }
    }
  }
}
